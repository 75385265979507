/* eslint-disable import/no-extraneous-dependencies */
// ** React Imports
import React, { useMemo, useState } from "react";

import * as Icon from "react-feather";

import { Row, Col, Badge } from "reactstrap";

import * as S from "./inputSearch.styles";

import { BarCodeScanner } from "components/Structure/BarcodeScreen";

import { ischeckIfAndNumber } from "utils";

export const InputSearch = ({
  search,
  setSearch,
  data,
  isLoading,
  title,
  isError,
  isScanner = true,
  width = "70%",
  widthBtn = "30%",
  inputBorderRadius = "50px",
  btnBorderRadiues = "20px",
  onSubmit,
}) => {
  const [isShowScanner, setIsShowScanner] = useState(false);
  const handleSearch = value => {
    setSearch(value);
  };

  const onClear = () => {
    setSearch("");
    onSubmit("");
  };

  const valueItem = useMemo(() => {
    if (ischeckIfAndNumber(search)) {
      return {
        value: search,
        isBlock: true,
      };
    }
    return {
      value: search,
      isBlock: false,
    };
  }, [search]);
  const collWidhItems =
    data?.length > 0 && !isLoading && search?.length >= 5 ? "6" : "12";

  return (
    <Row>
      <Col md={collWidhItems} className="text-center mt-1 mb-1">
        {isShowScanner && (
          <BarCodeScanner
            setSearch={handleSearch}
            close={value => setIsShowScanner(value)}
          />
        )}
        <S.NavSearch>
          <S.NavSearchItem width={width} className="text-start" show={true}>
            <S.InputCustom
              inputBorderRadius={inputBorderRadius}
              maxLength={valueItem?.isBlock === true ? 14 : 300}
              name="search"
              placeholder={title}
              value={valueItem?.value}
              onChange={e => handleSearch(e?.target?.value)}
            />
            {isError && valueItem?.value?.length > 0 ? (
              <Badge className="mx-1 mt-1" color="danger">
                EAN inválido
              </Badge>
            ) : null}
          </S.NavSearchItem>
          <S.NavSearchItem width={widthBtn} show={true}>
            <S.ButtonCustom
              btnBorderRadiues={btnBorderRadiues}
              onClick={() => onSubmit(search)}
              size="sm"
              id="buttonSearch"
              color="success"
              outline
            >
              <Icon.Search
                style={{
                  marginLeft: "5px !important",
                }}
                size={18}
              />
              &nbsp;
            </S.ButtonCustom>
            {isScanner && (
              <S.ButtonCustom
                btnBorderRadiues={btnBorderRadiues}
                onClick={() => setIsShowScanner(!isShowScanner)}
                size="sm"
                id="ScreeButtonSearch"
                color="warning"
                outline
              >
                <Icon.Camera
                  style={{
                    marginLeft: "5px !important",
                  }}
                  size={18}
                />
                &nbsp;
              </S.ButtonCustom>
            )}

            {search?.length > 0 && (
              <S.ButtonCustom
                btnBorderRadiues={btnBorderRadiues}
                onClick={() => onClear()}
                size="sm"
                id="filterOpen"
                color={"danger"}
                outline
              >
                <Icon.X size={18} />
                &nbsp;
              </S.ButtonCustom>
            )}
          </S.NavSearchItem>
        </S.NavSearch>
      </Col>
    </Row>
  );
};
