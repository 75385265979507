import { createSlice } from "@reduxjs/toolkit";

const initialRelatorioSkus = {
  params: {
    page: 1,
  },
  filters: {
    limit: 10,
  },
  showDivergent: false,
};

const date = new Date();
const firstDay = new Date(date.getFullYear(), date.getMonth(), 1, 1);
const currentDay = new Date(
  date.getFullYear(),
  date.getMonth(),
  date.getDate(),
  1,
);

const initialState = () => {
  return {
    ...initialRelatorioSkus,
    issueDateStart: firstDay,
    issueDateEnd: currentDay,
  };
};

export const relatorioSkusSlice = createSlice({
  name: "relatorioSkus",
  initialState,
  reducers: {
    handleChangeDate: (state, { payload }) => {
      state.issueDateStart = payload.issueDateStart;
      state.issueDateEnd = payload.issueDateEnd;
    },
    handleRowLimit: (state, { payload }) => {
      state.params.limit = payload;
    },
    handleParams: (state, { payload }) => {
      const field = payload?.field;
      state.params[field] = payload?.value;
      return state;
    },
    handleFilterParams: (state, { payload }) => {
      const field = payload?.field;
      state.params[field] = payload?.value;
      return state;
    },
    handleCleanFilterParams: (state, { payload }) => {
      delete state.filters.nome;
      delete state.filters.cod_produto;
      state.filters.limit = payload.limit;
      return state;
    },
    handleAddFilter: (state, { payload }) => {
      state.filters = payload;
    },
    handleShowType: (state, { payload }) => {
      state.showDivergent = payload;
    },
  },
});

export const {
  handleChangeDate,
  handleRowLimit,
  handleParams,
  handleFilterParams,
  handleCleanFilterParams,
  handleAddFilter,
  handleShowType,
} = relatorioSkusSlice.actions;

export default relatorioSkusSlice.reducer;
