import React from "react";
import { ReactComponent as funnellvl1 } from "./Icons/funnel-lvl1.svg";
import { ReactComponent as funnellvl1disabled } from "./Icons/funnel-lvl1-disable.svg";
import { ReactComponent as funnellvl2 } from "./Icons/funnel-lvl2.svg";
import { ReactComponent as funnellvl2disabled } from "./Icons/funnel-lvl2-disable.svg";
import { ReactComponent as funnellvl3 } from "./Icons/funnel-lvl3.svg";
import { ReactComponent as funnellvl3disabled } from "./Icons/funnel-lvl3-disable.svg";
import { ReactComponent as funnellvl4 } from "./Icons/funnel-lvl4.svg";
import { ReactComponent as funnellvl4disabled } from "./Icons/funnel-lvl4-disable.svg";
import { ReactComponent as funnellvl5 } from "./Icons/funnel-lvl5.svg";
import { ReactComponent as funnellvl5disabled } from "./Icons/funnel-lvl5-disable.svg";
import { ReactComponent as adicionar } from "./Icons/ico-adicionar-01.svg";
import { ReactComponent as analise } from "./Icons/ico-analise-01.svg";
import { ReactComponent as anexo } from "./Icons/ico-anexo-01.svg";
import { ReactComponent as arquivo } from "./Icons/ico-arquivo-01.svg";
import { ReactComponent as arquivoentrada } from "./Icons/ico-arquivo-entrada-01.svg";
import { ReactComponent as arquivosaida } from "./Icons/ico-arquivo-saida-01.svg";
import { ReactComponent as atencao } from "./Icons/ico-atencao-01.svg";
import { ReactComponent as atualizar } from "./Icons/ico-atualizar-01.svg";
import { ReactComponent as avancar } from "./Icons/ico-avancar-01.svg";
import { ReactComponent as blococampo } from "./Icons/ico-bloco-campo-01.svg";
import { ReactComponent as bookmark } from "./Icons/ico-bookmark-01.svg";
import { ReactComponent as bookmarksconteudo } from "./Icons/ico-bookmarks-conteudo-01.svg";
import { ReactComponent as busca } from "./Icons/ico-busca-01.svg";
import { ReactComponent as cadeado } from "./Icons/ico-cadeado-01.svg";
import { ReactComponent as calculadora } from "./Icons/ico-calculadora-01.svg";
import { ReactComponent as calendario } from "./Icons/ico-calendario-01.svg";
import { ReactComponent as carrinho } from "./Icons/ico-carrinho-01.svg";
import { ReactComponent as centralizar } from "./Icons/ico-centralizar-01.svg";
import { ReactComponent as certificado } from "./Icons/ico-certificado-01.svg";
import { ReactComponent as chat } from "./Icons/ico-chat-01.svg";
import { ReactComponent as classificacaofiscal } from "./Icons/ico-classificacao-fiscal-01.svg";
import { ReactComponent as codigobarras } from "./Icons/ico-codigo-barras-01.svg";
import { ReactComponent as codigobarrasalternativo } from "./Icons/ico-codigo-barras-alternativo-01.svg";
import { ReactComponent as compartilhar } from "./Icons/ico-compartilhar-01.svg";
import { ReactComponent as configuracoes } from "./Icons/ico-configuracoes-01.svg";
import { ReactComponent as configurarloja } from "./Icons/ico-configurar-loja-01.svg";
import { ReactComponent as confirmar } from "./Icons/ico-confirmar-01.svg";
import { ReactComponent as copiar } from "./Icons/ico-copiar-01.svg";
import { ReactComponent as cruzamentodados } from "./Icons/ico-cruzamento-dados-01.svg";
import { ReactComponent as csv } from "./Icons/ico-csv-01.svg";
import { ReactComponent as curtir } from "./Icons/ico-curtir-01.svg";
import { ReactComponent as dados } from "./Icons/ico-dados-01.svg";
import { ReactComponent as dashboard } from "./Icons/ico-dashboard-01.svg";
import { ReactComponent as dashrelatoriosinteligentes } from "./Icons/ico-dash-relatorios-inteligentes-01.svg";
import { ReactComponent as datalake } from "./Icons/ico-datalake-01.svg";
import { ReactComponent as decisao } from "./Icons/ico-decisao-01.svg";
import { ReactComponent as diagnosticofiscal } from "./Icons/ico-diagnostico-fiscal-01.svg";
import { ReactComponent as divergenciafiscal } from "./Icons/ico-divergencia-fiscal-01.svg";
import { ReactComponent as download } from "./Icons/ico-download-01.svg";
import { ReactComponent as dropdown } from "./Icons/ico-dropdown-01.svg";
import { ReactComponent as eanspiratas } from "./Icons/ico-eans-piratas-01.svg";
import { ReactComponent as editar } from "./Icons/ico-editar-01.svg";
import { ReactComponent as editarcalendario } from "./Icons/ico-editar-calendario-01.svg";
import { ReactComponent as editarlinha } from "./Icons/ico-editar-linha-01.svg";
import { ReactComponent as editarlista } from "./Icons/ico-editar-lista-01.svg";
import { ReactComponent as email } from "./Icons/ico-email-01.svg";
import { ReactComponent as entradadados } from "./Icons/ico-entrada-dados-01.svg";
import { ReactComponent as erp } from "./Icons/ico-erp-01.svg";
import { ReactComponent as estrela } from "./Icons/ico-estrela-01.svg";
import { ReactComponent as estrelavazada } from "./Icons/ico-estrela-vazada-01.svg";
import { ReactComponent as excel } from "./Icons/ico-excel-01.svg";
import { ReactComponent as fechar1 } from "./Icons/ico-fechar-01.svg";
import { ReactComponent as fechar2 } from "./Icons/ico-fechar-02.svg";
import { ReactComponent as fluxo } from "./Icons/ico-fluxo-01.svg";
import { ReactComponent as formula } from "./Icons/ico-formula-01.svg";
import { ReactComponent as fornecedor } from "./Icons/ico-fornecedor-01.svg";
import { ReactComponent as fornecedordinheiro } from "./Icons/ico-fornecedor-dinheiro-01.svg";
import { ReactComponent as funcaoarredondarmais } from "./Icons/ico-funcao-arredondar-mais-01.svg";
import { ReactComponent as funcaoarredondarmenos } from "./Icons/ico-funcao-arredondar-menos-01.svg";
import { ReactComponent as funcaocomparar } from "./Icons/ico-funcao-comparar-01.svg";
import { ReactComponent as funcaodivisao } from "./Icons/ico-funcao-divisao-01.svg";
import { ReactComponent as funcaomedia } from "./Icons/ico-funcao-media-01.svg";
import { ReactComponent as funcaomultiplicacao } from "./Icons/ico-funcao-multiplicacao-01.svg";
import { ReactComponent as funcaosoma } from "./Icons/ico-funcao-soma-01.svg";
import { ReactComponent as funcaosubstituir } from "./Icons/ico-funcao-substituir-01.svg";
import { ReactComponent as funcaosubtracao } from "./Icons/ico-funcao-subtracao-01.svg";
import { ReactComponent as gerenciamentoarquivo } from "./Icons/ico-gerenciamento-arquivo-01.svg";
import { ReactComponent as gerenciarproduto } from "./Icons/ico-gerenciar-produto-01.svg";
import { ReactComponent as grafico } from "./Icons/ico-grafico-01.svg";
import { ReactComponent as graficobarras } from "./Icons/ico-grafico-barras-01.svg";
import { ReactComponent as graficoborda } from "./Icons/ico-grafico-borda-01.svg";
import { ReactComponent as graficos } from "./Icons/ico-graficos-01.svg";
import { ReactComponent as grid } from "./Icons/ico-grid-01.svg";
import { ReactComponent as importararquivos } from "./Icons/ico-importar-arquivos-01.svg";
import { ReactComponent as impressora } from "./Icons/ico-impressora-01.svg";
import { ReactComponent as informacao } from "./Icons/ico-informacao-01.svg";
import { ReactComponent as integracoes } from "./Icons/ico-integracoes-01.svg";
import { ReactComponent as link } from "./Icons/ico-link-01.svg";
import { ReactComponent as livroaberto } from "./Icons/ico-livro-aberto-01.svg";
import { ReactComponent as localizacao } from "./Icons/ico-localizacao-01.svg";
import { ReactComponent as lupaanalise } from "./Icons/ico-lupa-analise-01.svg";
import { ReactComponent as menuradial } from "./Icons/ico-menu-radial-01.svg";
import { ReactComponent as monitoramentofiscal } from "./Icons/ico-monitoramento-fiscal-01.svg";
import { ReactComponent as monitoramentofornecedores } from "./Icons/ico-monitoramento-fornecedores-01.svg";
import { ReactComponent as mover } from "./Icons/ico-mover-01.svg";
import { ReactComponent as notificacao1 } from "./Icons/ico-notificacao.svg";
import { ReactComponent as notificacao2 } from "./Icons/ico-notificacao-01.svg";
import { ReactComponent as notificacao3 } from "./Icons/ico-notificacao-off-01.svg";
import { ReactComponent as operacao } from "./Icons/ico-operacao-01.svg";
import { ReactComponent as operacoes } from "./Icons/ico-operacoes-01.svg";
import { ReactComponent as parcial } from "./Icons/ico-parcial-01.svg";
import { ReactComponent as pdf } from "./Icons/ico-pdf-01.svg";
import { ReactComponent as pessoa } from "./Icons/ico-pessoa-01.svg";
import { ReactComponent as pin } from "./Icons/ico-pin-01.svg";
import { ReactComponent as produtosalterados } from "./Icons/ico-produtos-alterados-01.svg";
import { ReactComponent as proibido } from "./Icons/ico-proibido-01.svg";
import { ReactComponent as proximo } from "./Icons/ico-proximo-01.svg";
import { ReactComponent as qrcode } from "./Icons/ico-qrcode-01.svg";
import { ReactComponent as rede } from "./Icons/ico-rede-01.svg";
import { ReactComponent as redimensionar } from "./Icons/ico-redimensionar-01.svg";
import { ReactComponent as relatoriosinteligentes } from "./Icons/ico-relatorios-inteligentes-01.svg";
import { ReactComponent as relogio } from "./Icons/ico-relogio-01.svg";
import { ReactComponent as resultados } from "./Icons/ico-resultados-01.svg";
import { ReactComponent as resumo } from "./Icons/ico-resumo-01.svg";
import { ReactComponent as revisardescricao } from "./Icons/ico-revisar-descricao-01.svg";
import { ReactComponent as rolarbaixo } from "./Icons/ico-rolar-baixo-01.svg";
import { ReactComponent as rolarcima } from "./Icons/ico-rolar-cima-01.svg";
import { ReactComponent as selecionar } from "./Icons/ico-selecionar-01.svg";
import { ReactComponent as setabaixo } from "./Icons/ico-seta-baixo-01.svg";
import { ReactComponent as setacima } from "./Icons/ico-seta-cima-01.svg";
import { ReactComponent as setadireita } from "./Icons/ico-seta-direita-01.svg";
import { ReactComponent as setaesquerda } from "./Icons/ico-seta-esquerda-01.svg";
import { ReactComponent as sped } from "./Icons/ico-sped-01.svg";
import { ReactComponent as sql } from "./Icons/ico-sql-01.svg";
import { ReactComponent as subpasta } from "./Icons/ico-subpasta-01.svg";
import { ReactComponent as suporte } from "./Icons/ico-suporte-01.svg";
import { ReactComponent as tabela } from "./Icons/ico-tabela-01.svg";
import { ReactComponent as telefone } from "./Icons/ico-telefone-01.svg";
import { ReactComponent as terminal } from "./Icons/ico-terminal-01.svg";
import { ReactComponent as tesoura } from "./Icons/ico-tesoura-01.svg";
import { ReactComponent as textarea } from "./Icons/ico-text-area-01.svg";
import { ReactComponent as ticket } from "./Icons/ico-ticket-01.svg";
import { ReactComponent as transporte } from "./Icons/ico-transporte-01.svg";
import { ReactComponent as usuario } from "./Icons/ico-usuario-01.svg";
import { ReactComponent as verificado } from "./Icons/ico-verificado-01.svg";
import { ReactComponent as voltar } from "./Icons/ico-voltar-01.svg";
import { ReactComponent as www } from "./Icons/ico-www-01.svg";
import { ReactComponent as xml } from "./Icons/ico-xml-01.svg";
import { ReactComponent as filtro1 } from "./Icons/ico-filtro-01.svg";

import { ReactComponent as anterior } from "./Icons/ico-anterior-01.svg";
import { ReactComponent as irpara } from "./Icons/ico-ir-para-01.svg";
import { ReactComponent as paginacaoprimeira } from "./Icons/ico-paginacao-primeira-01.svg";
import { ReactComponent as paginacaoultima } from "./Icons/ico-paginacao-ultima-01.svg";
import { ReactComponent as proxima } from "./Icons/ico-proxima.svg";

// type Dictonary = {
//   [key: string]: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
// }

const dict = {
  funnellvl1,
  funnellvl1disabled,
  funnellvl2,
  funnellvl2disabled,
  funnellvl3,
  funnellvl3disabled,
  funnellvl4,
  funnellvl4disabled,
  funnellvl5,
  funnellvl5disabled,
  adicionar,
  analise,
  anexo,
  arquivo,
  arquivoentrada,
  arquivosaida,
  atencao,
  atualizar,
  avancar,
  blococampo,
  bookmark,
  bookmarksconteudo,
  busca,
  cadeado,
  calculadora,
  calendario,
  carrinho,
  centralizar,
  certificado,
  chat,
  classificacaofiscal,
  codigobarras,
  codigobarrasalternativo,
  compartilhar,
  configuracoes,
  configurarloja,
  confirmar,
  copiar,
  cruzamentodados,
  csv,
  curtir,
  dados,
  dashboard,
  dashrelatoriosinteligentes,
  datalake,
  decisao,
  diagnosticofiscal,
  divergenciafiscal,
  download,
  dropdown,
  eanspiratas,
  editar,
  editarcalendario,
  editarlinha,
  editarlista,
  email,
  entradadados,
  erp,
  estrela,
  estrelavazada,
  excel,
  fechar1,
  fechar2,
  fluxo,
  formula,
  fornecedor,
  fornecedordinheiro,
  funcaoarredondarmais,
  funcaoarredondarmenos,
  funcaocomparar,
  funcaodivisao,
  funcaomedia,
  funcaomultiplicacao,
  funcaosoma,
  funcaosubstituir,
  funcaosubtracao,
  gerenciamentoarquivo,
  gerenciarproduto,
  grafico,
  graficobarras,
  graficoborda,
  graficos,
  grid,
  importararquivos,
  impressora,
  informacao,
  integracoes,
  link,
  livroaberto,
  localizacao,
  lupaanalise,
  menuradial,
  monitoramentofiscal,
  monitoramentofornecedores,
  mover,
  notificacao1,
  notificacao2,
  notificacao3,
  operacao,
  operacoes,
  parcial,
  pdf,
  pessoa,
  pin,
  produtosalterados,
  proibido,
  proximo,
  qrcode,
  rede,
  redimensionar,
  relatoriosinteligentes,
  relogio,
  resultados,
  resumo,
  revisardescricao,
  rolarbaixo,
  rolarcima,
  selecionar,
  setabaixo,
  setacima,
  setadireita,
  setaesquerda,
  sped,
  sql,
  subpasta,
  suporte,
  tabela,
  telefone,
  terminal,
  tesoura,
  textarea,
  ticket,
  transporte,
  usuario,
  verificado,
  voltar,
  www,
  xml,
  anterior,
  irpara,
  paginacaoprimeira,
  paginacaoultima,
  proxima,
  filtro1,
};

// type IconComponentProps = {
//   name: string;
//   color?: string;
//   size?: number;
//   sizeFollowOneAxis?: 'width' | 'height' | null;
//   handleClick?: () => void;
//   className?: string;
// }

export const Icon = ({
  name,
  color = "#000",
  size = 24,
  sizeFollowOneAxis = null,
  handleClick,
  className,
}) => {
  if (!name) {
    return null;
  }

  const IconComponent = dict[name.toLowerCase()];

  return (
    IconComponent && (
      <IconComponent
        fill={color}
        width={
          sizeFollowOneAxis
            ? sizeFollowOneAxis === "width"
              ? `${size}px`
              : undefined
            : `${size}px`
        }
        height={
          sizeFollowOneAxis
            ? sizeFollowOneAxis === "height"
              ? `${size}px`
              : undefined
            : `${size}px`
        }
        onClick={handleClick}
        className={className}
        style={handleClick && { cursor: "pointer" }}
      />
    )
  );
};
