import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";

import { handleParamsStartEnd } from "redux/taxAnalises";
import { hasUserInternal } from "utils/context/acl";

import { Date } from "components/TaxAnalysis/List/Header/Filter/Date";
import { ActionsExport } from "components/TaxAnalysis/List/Header/ActionsExports";

import { Row, Col } from "reactstrap";

export const ActionsHeader = ({ disabledBtn }) => {
  const { issue_date_end: end, issue_date_start: start } = useSelector(
    state => state.taxAnalises,
  );

  const { userData } = useSelector(state => state.auth);

  const dispatch = useDispatch();

  const handleOnChange = value => {
    if (value[0] && value[1]) {
      dispatch(
        handleParamsStartEnd({
          issue_date_end: value[1] ? format(value[1], "yyyy-MM-dd") : null,
          issue_date_start: format(value[0], "yyyy-MM-dd"),
        }),
      );
    }
  };

  return (
    <>
      <Row>
        <Col md="9" className="d-none d-lg-block">
          <Date handleOnChange={handleOnChange} value={[start, end]} />
        </Col>
        <Col md="3">
          {hasUserInternal(userData) && (
            <ActionsExport disabledBtn={disabledBtn} />
          )}
        </Col>
      </Row>
    </>
  );
};
