import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { FILE_EXPORT_TYPE } from "constants/Types/files.types";

import { BtnExport } from "components/Shared/BtnExport";
import { useQueimaAndRiscoActions } from "useCases/reports/QueimaCaixa";
import { hasUserInternal } from "utils/context/acl";

export const ActionsExport = ({ disabledBtn }) => {
  const { onSend, isLoading } = useQueimaAndRiscoActions();

  const { userData } = useSelector(state => state.auth);

  const paramsTaxAnalises = useSelector(state => state.taxAnalises);

  const handleExport = ({ type }) =>
    onSend({
      ...paramsTaxAnalises,
      type_export: type.toUpperCase(),
      user_name: userData?.name,
      user_email: userData?.email,
      tipo: paramsTaxAnalises?.tipo,
    });

  const activesList = useMemo(() => {
    const list = [FILE_EXPORT_TYPE.CSV, FILE_EXPORT_TYPE.XLSX];

    if (
      hasUserInternal(userData) &&
      paramsTaxAnalises.taxType === "icms" &&
      paramsTaxAnalises.tipo === "queima"
    ) {
      list.push(FILE_EXPORT_TYPE.SPED);
    }

    return list;
  }, [userData, paramsTaxAnalises.taxType, paramsTaxAnalises.tipo]);

  return (
    <BtnExport
      disabled={disabledBtn}
      isLoading={isLoading}
      onHandleExport={handleExport}
      // TODO: Retirar e criar constants para centralizar os tipos!
      actives={activesList}
    />
  );
};
