import React from "react";

import classnames from "classnames";

import { Meh } from "react-feather";
import { Spinner } from "reactstrap";

import { useSkin } from "@hooks/useSkin";

import * as S from "./blockUi.styles";

export const BlockUi = ({
  children,
  className,
  overlayColor,
  show,
  color,
  overlayChildren: OverlayChildren,
  ...props
}) => (
  <S.BlockUi
    color={color}
    className={classnames("block-ui", {
      [className]: className,
      show,
    })}
    {...props}
  >
    {children}
    {show && (
      <div
        className="overlay" /*eslint-disable */
        {...(show && overlayColor
          ? { style: { backgroundColor: overlayColor } }
          : {})}
        /*eslint-enable */
      >
        {OverlayChildren}
      </div>
    )}
  </S.BlockUi>
);

const BlankContent = ({ title, description, childrenFilter }) => (
  <S.BlockUiContent>
    {title ? <h4>{title}</h4> : null}
    <Meh size="50" />
    <h6>{description || "Sem dados para o periodo selecionado"}</h6>
    {childrenFilter || ""}
  </S.BlockUiContent>
);

export const BlockUiLoading = ({ description }) => (
  <S.BlockUiContent>
    <Spinner color="primary" size="lg" />
    <h6 className="text-primary">{description}</h6>
  </S.BlockUiContent>
);

export const BlockUiBlankData = ({
  show,
  title,
  description,
  descriptionLoading = "Carregando",
  isLoading = false,
  children,
  childrenFilter,
  ...props
}) => {
  const { skin } = useSkin();

  return (
    <BlockUi
      color={skin}
      show={show}
      overlayChildren={
        isLoading ? (
          <BlockUiLoading description={descriptionLoading} />
        ) : (
          <BlankContent
            childrenFilter={childrenFilter}
            title={title}
            description={description}
          />
        )
      }
      {...props}
    >
      {show ? <S.BlockBlur>{children}</S.BlockBlur> : children}
    </BlockUi>
  );
};
