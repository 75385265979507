// ** React Imports
import React from "react";
import { useSkin } from "@hooks/useSkin";

import { Spinner } from "reactstrap";

import * as S from "./progressPage.styles";

export const ProgressPage = ({
  title = "Aguarde, estamos carregando seus dados....",
}) => {
  const { skin } = useSkin();

  return (
    <S.ProgressContainer
      color={skin === "dark" ? "#283046" : "#fff"}
      className="text-center pt-4 pb-4"
    >
      <Spinner color="primary" />
      <h4>{title}</h4>
    </S.ProgressContainer>
  );
};
