import { useState } from "react";

import { useApiNfeFadel } from "./useAPI";
import { useToast } from "context/ToastContext";

export const useAPINfeFadel = apiRef => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({});

  const { addToast } = useToast();

  const api = useApiNfeFadel();

  const onSend = async ({
    messageSuccess,
    messageFail,
    endpoint,
    type = "POST",
    data: dataFields = {},
  }) => {
    try {
      setIsLoading(true);
      const { data } = await (apiRef ?? api)({
        method: type,
        url: endpoint,
        data: dataFields,
      });

      if (data.response ?? data.success) {
        addToast({
          type: "success",
          title: "SUCESSO",
          description: data?.msg || messageSuccess,
        });
        setData(data);
      }
      setData(data);

      return data;
    } catch (err) {
      setData(err);
      addToast({
        type: "error",
        title: "FALHA",
        description: messageFail,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    data,
    onSend,
  };
};
