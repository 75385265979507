import { TextField } from "components/Form";
import styled from "styled-components";

export const BtnConfirm = styled.div`
  height: 32px;
  width: 32px;
`;

export const InputChangePage = styled(TextField)`
  height: 32px !important;
  text-align: center !important;
`;

export const DivMaxW = styled.div`
  max-width: 60px;
  width: 5%;
`;
