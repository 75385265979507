import { useState, useCallback } from "react";
import { useCmfAPI, useFetchMixAPi1 } from "hooks";

export const useFetchExecutarSQLCmfAgendador = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState("");
  const [columns, setColumns] = useState([]);

  const apiCmfAgendador = useCmfAPI();
  const fetch = useCallback(
    async body => {
      setLoading(true);
      await apiCmfAgendador
        .post("/executar-sql", body)
        .then(res => {
          setLoading(false);
          setData(res?.data?.data?.result);
          setColumns(res?.data?.data?.columns);
        })
        .catch(error => {
          const success = error?.response?.data?.data?.message;
          setError(success);
          setLoading(false);
          setData([]);
          setColumns([]);
        });
    },
    [apiCmfAgendador, setColumns, setData],
  );

  return { fetch, columns, data, isLoading: loading, error };
};

export const useGetCliIdByCnpj = cnpj =>
  useFetchMixAPi1(cnpj && `/cmf/get-cli-by-cnpj/${cnpj}`);
