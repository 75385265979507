import React from "react";
import { ReactComponent as sum } from "assets/Icons/ico-funcao-soma.svg";
import { ReactComponent as subtract } from "assets/Icons/ico-funcao-subtracao.svg";
import { ReactComponent as roundup } from "assets/Icons/ico-funcao-arredondar-mais.svg";
import { ReactComponent as rounddown } from "assets/Icons/ico-funcao-arredondar-menos.svg";
import { ReactComponent as compare } from "assets/Icons/ico-funcao-comparar.svg";
import { ReactComponent as divide } from "assets/Icons/ico-funcao-divisao.svg";
import { ReactComponent as average } from "assets/Icons/ico-funcao-media.svg";
import { ReactComponent as multiply } from "assets/Icons/ico-funcao-multiplicacao.svg";
import { ReactComponent as replace } from "assets/Icons/ico-funcao-substituir.svg";
import { ReactComponent as back } from "assets/Icons/ico-voltar.svg";
import { ReactComponent as forward } from "assets/Icons/ico-avancar.svg";
import { ReactComponent as search } from "assets/Icons/ico-busca.svg";
import { ReactComponent as close } from "assets/Icons/ico-close.svg";
import { ReactComponent as close2 } from "assets/Icons/ico-close2.svg";
import { ReactComponent as check } from "assets/Icons/ico-check.svg";
import { ReactComponent as person } from "assets/Icons/ico-pessoa.svg";
import { ReactComponent as locker } from "assets/Icons/ico-criptografia.svg";
import { ReactComponent as arrowdown } from "assets/Icons/ico-arrow-down.svg";
import { ReactComponent as arrowup } from "assets/Icons/ico-arrow-up.svg";
import { ReactComponent as arrowright } from "assets/Icons/ico-arrow-right.svg";
import { ReactComponent as arrowleft } from "assets/Icons/ico-arrow-left.svg";

import { ReactComponent as warning } from "assets/Icons/ico-atencao.svg";
import { ReactComponent as star } from "assets/Icons/ico-estrela.svg";
import { ReactComponent as like } from "assets/Icons/ico-like.svg";
import { ReactComponent as link } from "assets/Icons/ico-link.svg";
import { ReactComponent as notification } from "assets/Icons/ico-notificacao.svg";
import { ReactComponent as ticket } from "assets/Icons/ico-ticket.svg";
import { ReactComponent as email } from "assets/Icons/ico-email.svg";
import { ReactComponent as network } from "assets/Icons/ico-network.svg";
import { ReactComponent as qrcode } from "assets/Icons/ico-qr-code.svg";
import { ReactComponent as analysis } from "assets/Icons/ico-analise.svg";
import { ReactComponent as edit } from "assets/Icons/ico-editar.svg";
import { ReactComponent as subfolder } from "assets/Icons/ico-subpasta.svg";

import { ReactComponent as sped } from "assets/Icons/ico-sped.svg";
import { ReactComponent as flow } from "assets/Icons/ico-fluxo.svg";
import { ReactComponent as operations } from "assets/Icons/ico-operacoes.svg";
import { ReactComponent as pdf } from "assets/Icons/ico-pdf.svg";
import { ReactComponent as csv } from "assets/Icons/ico-csv.svg";
import { ReactComponent as excel } from "assets/Icons/ico-ms-excel.svg";
import { ReactComponent as xml } from "assets/Icons/ico-xml.svg";
import { ReactComponent as google } from "assets/Icons/ico-google.svg";

const dict = {
  sum,
  subtract,
  roundup,
  rounddown,
  compare,
  divide,
  average,
  multiply,
  replace,
  back,
  forward,
  search,
  sped,
  flow,
  operations,
  pdf,
  csv,
  excel,
  xml,
  close,
  close2,
  check,
  person,
  locker,
  warning,
  star,
  like,
  link,
  notification,
  ticket,
  email,
  network,
  qrcode,
  analysis,
  edit,
  arrowdown,
  arrowup,
  arrowright,
  arrowleft,
  subfolder,
  google,
};

const IconComponent = ({
  name,
  color = "#fff",
  size = 24,
  onClick,
  className,
}) => {
  if (!name) {
    return null;
  }
  const Icon = dict[name.toLowerCase()];

  return (
    Icon && (
      <Icon
        fill={color}
        width={`${size}px`}
        height={`${size}px`}
        onClick={onClick}
        className={className}
      />
    )
  );
};

export default IconComponent;
