import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Card } from "reactstrap";

import { ChevronDown } from "react-feather";

import { AlertList, ProgressPage, DataTable } from "components/Structure";

import {
  handleParamsNote,
  handleSetColumnsProducts,
  handleParamsPage,
} from "redux/taxAnalises";

import { columns } from "./columns";

import { TableListNotes } from "../Notes";

import DataTableMobile from "components/Structure/DataTable/MobileTable";

const customStyles = {
  rows: {
    style: {
      padding: "10px 0 10px 0",
    },
  },
};

export const ListProducts = ({ data, dataRes, isLoading, paramsSearch }) => {
  const [currentRow, setCurrentRow] = useState(null);

  const { columnsProducts, tipo, params } = useSelector(
    state => state.taxAnalises,
  );

  const dispatch = useDispatch();

  const handlePageChange = useCallback(
    page => {
      dispatch(handleParamsNote({ field: "page", value: page }));
    },
    [dispatch],
  );

  const handlePageChangeProduct = useCallback(
    page => {
      dispatch(handleParamsPage({ page }));
    },
    [dispatch],
  );

  const handlePageByInput = page => dispatch(handleParamsPage({ page }));

  const handleChangeFilter = useCallback(
    (field, value) => {
      dispatch(handleParamsNote({ field, value }));
    },
    [dispatch],
  );

  useEffect(() => {
    if (tipo) {
      const items = (columns || []).filter(
        item => item?.isType === tipo || !item?.isType,
      );
      dispatch(handleSetColumnsProducts([...items]));
    }
  }, [tipo, dispatch]);

  return (
    <>
      <Card className="p-0 d-none d-lg-block">
        <DataTable
          pagination
          sortServer
          paginationServer
          responsive={true}
          progressPending={isLoading}
          columns={columnsProducts}
          customStyles={customStyles}
          data={(data || []).map((item, key) => ({
            ...item,
            key,
          }))}
          sortIcon={<ChevronDown />}
          className="react-dataTable"
          defaultSortField="invoiceId"
          progressComponent={<ProgressPage />}
          noDataComponent={
            <AlertList title="Sem dados para listagem" description="" />
          }
          page={params?.page}
          totalPages={dataRes?.max_pages}
          expandableRows
          expandableRowExpanded={row => row === currentRow}
          expandOnRowClicked
          onRowClicked={row => setCurrentRow(row)}
          onRowExpandToggled={(bool, row) => {
            setCurrentRow(row);
            dispatch(handleParamsNote({ field: "page", value: 1 }));
          }}
          handlePageChange={handlePageChangeProduct}
          expandableRowsComponent={data => {
            return (
              <TableListNotes
                data={data}
                paramsSearch={paramsSearch}
                handlePageChange={handlePageChange}
                handleChangeFilter={handleChangeFilter}
              />
            );
          }}
          inputPagination
          marginInput
          handleInputPagination={handlePageByInput}
        />
      </Card>
      <DataTableMobile
        columns={columnsProducts}
        data={data || []}
        page={params?.page}
        totalDocs={data?.length}
        totalPages={dataRes?.max_pages}
        isLoading={isLoading}
        expandableRows
        titleAdding="Notas Fiscais"
        childrenExpendade={TableListNotes}
      />
    </>
  );
};
