import { createSlice } from "@reduxjs/toolkit";

export const initialStores = {
  list: [],
  params: {
    companyName: "",
    cnpj: "",
    city: "",
    page: 1,
    limit: 20,
  },
  totalPages: 0,
  showModal: {
    type: "",
    show: false,
    store: {},
  },
  showSideBar: false,
  ignoradosList: {
    page: 1,
    limit: 10,
  },
  modal: {
    open: false,
    value: "",
  },
  removeStore: false,
};

const initialState = () => {
  return initialStores;
};

export const storesSlice = createSlice({
  name: "stores",
  initialState,
  reducers: {
    handleSetList: (state, { payload }) => {
      state.list = [...payload.list];
      state.totalPages = payload.totalPages;
    },
    handleSetPage: (state, { payload }) => {
      state.page = payload.page;
    },
    handleSetStores: (state, { payload }) => {
      state.showModal.store = payload;
    },
    handleSetPageAndLimit: (state, { payload }) => {
      if (payload.page) {
        state.params.page = payload.page;
      }
      if (payload.limit) {
        state.params.limit = payload.limit;
      }
    },
    handleParamsLimit: (state, { payload }) => {
      state.params.limit = payload.limit;
    },
    handleOpenModal: (state, { payload }) => {
      state.showModal.type = payload.type;
      state.showModal.show = payload.showModal;
    },
    handleClearStore: state => {
      state.showModal.store = {};
    },
    handleOpenOffCanvas: (state, { payload }) => {
      state.showSideBar = payload;
    },
    handlePaginationIgnorados: (state, { payload }) => {
      state.ignoradosList.page = payload;
    },
    handleChangeModal: (state, { payload }) => {
      state.modal.open = payload.open;
      state.modal.value = payload.value;
    },
    handleRemoveStore: (state, { payload }) => {
      state.removeStore = payload;
    },
  },
});

export const {
  handleSetList,
  handleSetPage,
  handleSetStores,
  handleSetPageAndLimit,
  handleParamsLimit,
  handleOpenModal,
  handleOpenOffCanvas,
  handleClearStore,
  handlePaginationIgnorados,
  handleChangeModal,
  handleRemoveStore,
} = storesSlice.actions;

export default storesSlice.reducer;
