import { createSlice } from "@reduxjs/toolkit";

export const inicialClassificao = {
  params: {
    limit: 10,
    page: 1,
  },
  detail: {
    uf_sigla: "",
    op_sigla: "",
    ctd_nome: "",
  },
  updatedState: false,
};

const initialState = () => inicialClassificao;

export const classificacaoSlice = createSlice({
  name: "classificacao",
  initialState,
  reducers: {
    handleParams: (state, { payload }) => {
      const field = payload?.field;
      state.params[field] = payload?.value;
      if (!payload?.value) delete state.params[field];
      return state;
    },
    handleClearParams: (state, { payload }) => {
      const field = payload?.field;
      delete state.params[field];
      return state;
    },
    handleDetail: (state, { payload }) => {
      const field = payload?.field;
      state.detail[field] = payload.value;
      return state;
    },
    handleUpdateState: (state, { payload }) => {
      state.updatedState = payload;
      return state;
    },
    handleCleanFilter: state => {
      state.params = {};
      return state;
    },
  },
});

export const {
  handleParams,
  handleClearParams,
  handleDetail,
  handleUpdateState,
  handleCleanFilter,
} = classificacaoSlice.actions;

export default classificacaoSlice.reducer;
