import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

const dateCurrent = new Date();

const initialInvoice = {
  list: [],
  page: 1,
  limit: 20,
  type: "",
  order: {
    column: "data_emissao",
    type: "desc",
  },
  association: {
    show: false,
    prod_id: "",
    data: {},
  },
  prodSelected: {},
  currentMonth: [],
  totalPages: 0,
  isShowFilter: false,
  showBookKeeping: "",
  bookkeping: {},
  bookkepingListItens: [],
  listTabs: [],
  tabSelected: {
    label: "ORIGINAL",
    value: "origin",
    isChecked: true,
  },
  isShowDialog: {
    show: false,
    id: "",
    data: {},
    modelo: "",
    user_id: "",
    tipo: "",
  },
  columns: [],
  filters: [],
  downloadNoteModal: false,
  downloadNoteMonth: dateCurrent,
};

const initialState = () => {
  return initialInvoice;
};

export const invoiceSlice = createSlice({
  name: "invoice",
  initialState,
  reducers: {
    handleSelectProduct: (state, { payload }) => {
      state.prodSelected = payload.item;
    },
    handleOpenModalAssociation: (state, { payload }) => {
      state.association.show = payload.show;
      state.association.prod_id = payload.id;
      state.association.data = payload.row;
    },
    handleSetList: (state, { payload }) => {
      state.list = [...payload.list];
    },
    handleSetData: (state, { payload }) => {
      state.isShowDialog.data = payload?.data;
    },
    handleSetPageAndLimit: (state, { payload }) => {
      if (payload.page) {
        state.page = payload.page;
      }
      if (payload.type) {
        state.type = payload.type;
      }
      if (payload.totalPages) {
        state.totalPages = payload.totalPages;
      }
      if (payload.limit) {
        state.limit = payload.limit;
      }
      if (payload.currentMonth) {
        state.currentMonth = payload.currentMonth;
      }
    },
    handleColumns: (state, { payload }) => {
      state.columns = payload;
    },

    handleOnChangeColumns: (state, { payload }) => {
      const { name, omit } = payload;
      const actions = state.columns.filter(
        item => !item.name && item.disabledDrop,
      );
      const selected = state.columns
        .filter(item => item.name && !item.disabledDrop && item?.name === name)
        .map(item => {
          return {
            ...item,
            omit: !omit ? true : false,
          };
        });

      const others = state.columns.filter(
        item => item.name && !item.disabledDrop && item?.name !== name,
      );

      const newList = [...actions, ...selected, ...others];

      const listPositions = newList.sort(
        (itemA, itemB) => itemA.position - itemB.position,
      );
      state.columns = listPositions;
    },
    handleTabs: (state, { payload }) => {
      state.listTabs = [...payload];
    },
    handleToogleTabClose: (state, { payload }) => {
      state.tabSelected = payload;
    },
    handleToogleTab: (state, { payload }) => {
      const res = state.listTabs
        .filter(item => item.value === payload?.value)
        .map(d => {
          state.tabSelected = { ...d, isChecked: true };
          return { ...d, isChecked: true };
        });

      const res2 = state.listTabs
        .filter(item => item.value !== payload?.value)
        .map(d => {
          return { ...d, isChecked: false };
        });
      const newList = [...res2, ...res];
      const listPositions = newList.sort(
        (itemA, itemB) => itemA.position - itemB.position,
      );
      state.listTabs = listPositions;
    },
    handleOnChangeFilter: (state, { payload }) => {
      const list = [...state.filters];
      list[payload?.key].columnField[payload?.field] = payload?.value;
      state.filters = list;
    },
    handleToogleFilters: (state, { payload }) => {
      state.isShowFilter = payload.isShowFilter;
    },
    handleToogleDialog: (state, { payload }) => {
      state.isShowDialog.show = payload.show;
      state.isShowDialog.id = payload.id;
      state.isShowDialog.data = payload.data;
      state.isShowDialog.modelo = payload.modelo;
    },
    handleToogleDialogFields: (state, { payload }) => {
      Object.keys(payload).map(field => {
        const value = payload[field];

        return (state.isShowDialog[field] = value);
      });
    },
    handleAddFilters: (state, { payload }) => {
      state.filters = payload;
    },
    handleRemoveFilterUnic: (state, action) => {
      const { id } = action.payload;
      state.filters = state.filters.filter(item => item.id !== id);
    },
    handleBookkepingItem: (state, { payload }) => {
      if (payload.bookkepingListItens) {
        state.bookkepingListItens = payload.bookkepingListItens;
      }

      if (payload.bookkeping) {
        state.bookkeping = payload;
      }
    },
    handleBookKeeping: (state, { payload }) => {
      state.showBookKeeping = payload;
    },
    handleRemoveFilterAll: state => {
      state.filters = [
        {
          id: uuidv4(),
          columnField: {
            columnOrField: "and",
            columnField: "prod_nome",
            operatorValue: "equals",
            value: "",
          },
        },
      ];
    },
    handleDownloadNoteMonth: (state, { payload }) => {
      state.downloadNoteMonth = payload;
    },
    handleDownloadNoteModal: (state, { payload }) => {
      state.downloadNoteModal = payload;
    },
  },
});

export const {
  handleTabs,
  handleSetList,
  handleToogleFilters,
  handleOnChangeFilter,
  handleColumns,
  handleAddFilters,
  handleRemoveFilterUnic,
  handleBookkepingItem,
  handleRemoveFilterAll,
  handleSetPageAndLimit,
  handleOnChangeColumns,
  handleToogleDialog,
  handleToogleDialogFields,
  handleToogleTab,
  handleBookKeeping,
  handleToogleTabClose,
  handleSetData,
  handleSelectProduct,
  handleOpenModalAssociation,
  handleDownloadNoteMonth,
  handleDownloadNoteModal,
} = invoiceSlice.actions;

export default invoiceSlice.reducer;
