import React from "reactstrap";

import { formatCurrency } from "utils";

import * as S from "./table.styles";

const percente = value =>
  Number(value / 100).toLocaleString(undefined, {
    style: "percent",
    minimumFractionDigits: 2,
  });

export const DataTableMobile = ({ columns, dataItem }) => {
  const checkFormatValue = (type, value) => {
    if (type === "monay") {
      return formatCurrency(value);
    }

    if (type === "percent") {
      return percente(value);
    }

    return value;
  };

  return (
    <S.TableCard>
      <S.TableStyled>
        {(columns || [])
          .filter(
            item =>
              !item?.isMinTitle &&
              !item?.isInfo &&
              !item?.isModal &&
              !item?.isProcess &&
              !item?.isOmitMobile &&
              !item?.isShowTag &&
              !item?.isTitle &&
              !item?.isSubTitle &&
              item?.sortField,
          )
          .map(item => (
            <S.TrStyled>
              <S.TdStyled>{item?.name}</S.TdStyled>
              <S.TdStyled>
                {checkFormatValue(
                  item?.typeFormatValue,
                  dataItem[item?.sortField],
                )}
              </S.TdStyled>
            </S.TrStyled>
          ))}
      </S.TableStyled>
    </S.TableCard>
  );
};
