import React, { useCallback, useEffect, useRef, useState } from "react";
import { Popover, PopoverHeader, PopoverBody, Button } from "reactstrap";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import {
  useBloqueioProduto,
  useGetProdutoBloqueado,
} from "useCases/reports/bloqueioProduto";

import { BloqueioProduto, DesbloqueioProduto } from "../form";
import Avatar from "@components/avatar";

import { dateFormat } from "utils";
import { handleConfirmAlertHtml } from "components/Structure/ConfirmAlert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBandage } from "@fortawesome/free-solid-svg-icons";
import { useGetCliIdByCnpj } from "useCases/cmf";
import { useSelector, useDispatch } from "react-redux";
import { handleAddListBlockedProduct } from "redux/relatorioProdutos";

const MySwal = withReactContent(Swal);

export const ButtonsBloqueio = ({ data, keyItem = 0 }) => {
  const [isSetValue, setIsValue] = useState(false);
  const [isSetSubmit, setIsSubmit] = useState(false);
  const { blockedProducts } = useSelector(state => state.relatorioProdutos);

  const { data: cliId } = useGetCliIdByCnpj(data?.cnpj);

  const cmfCliId = data?.cmf_cli_id ?? cliId?.cmf_cli_id;

  const ean =
    data?.pcn_ean === "SEM GTIN" ? data?.pcn_cod_interno : data?.pcn_ean;

  const {
    data: produtoBloqueado,
    isLoading,
    revalidate,
  } = useGetProdutoBloqueado({
    cmf_cli_id: cmfCliId,
    pcn_cod_interno: data?.pcn_cod_interno,
  });

  const {
    onSendLockProduct,
    onSendUnlockProduct,
    isLoading: isLoadingSolicitation,
  } = useBloqueioProduto();

  const infoProduct = produtoBloqueado?.[0];

  const { solicitado_bloqueio: isValidBloqueio } = infoProduct ?? {};

  const ref = useRef();
  const [popoverOpen, setPopoverOpen] = useState(false);
  const dispatch = useDispatch();

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    setValue,
    reset,
  } = useForm();

  const htmlText = `<h4> ${data?.pcn_nome}</h4>
                   <h5> Código Produto: <strong>${data?.pcn_cod_interno}</strong></h5> `;

  const onSendDesbloqueio = useCallback(
    fields => {
      onSendUnlockProduct({
        cmf_cli_id: cmfCliId,
        pcn_cod_interno: [{ ...fields }],
        tributacao: {
          svc_cst_cli: data?.svc_cst_cli,
          svc_alq_cli: data?.svc_alq_cli,
          svc_cst_mix: data?.svc_cst_mix,
          svc_alq_mix: data?.svc_alq_mix,
          snc_cst_cli: data?.snc_cst_cli,
          snc_alq_cli: data?.snc_alq_cli,
          snc_cst_mix: data?.snc_cst_mix,
          snc_alq_mix: data?.snc_alq_mix,
          mxf_svc_rbc: data?.mxf_svc_rbc,
          pcn_svc_rbc: data?.pcn_svc_rbc,
          mxf_svc_rbcst: data?.mxf_svc_rbcst,
          pcn_svc_rbcst: data?.pcn_svc_rbcst,
          mxf_snc_rbc: data?.mxf_snc_rbc,
          pcn_snc_rbc: data?.pcn_snc_rbc,
          mxf_snc_rbcst: data?.mxf_snc_rbcst,
          pcn_snc_rbcst: data?.pcn_snc_rbcst,
          div_fecp: data?.div_fecp,
          pcn_fecp: data?.pcn_fecp,
          mxf_fecp: data?.mxf_fecp,
          div_fecp_st: data?.div_fecp_st,
          pcn_fecp_st: data?.pcn_fecp_st,
          mxf_fecp_st: data?.mxf_fecp_st,
        },
      });
      setIsSubmit(true);
    },
    [data, onSendUnlockProduct, cmfCliId],
  );

  const onSend = useCallback(
    fields => {
      onSendLockProduct({
        cmf_cli_id: cmfCliId,
        pcn_cod_interno: [{ ...fields }],
        tributacao: {
          svc_cst_cli: data?.svc_cst_cli,
          svc_alq_cli: data?.svc_alq_cli,
          svc_cst_mix: data?.svc_cst_mix,
          svc_alq_mix: data?.svc_alq_mix,
          snc_cst_cli: data?.snc_cst_cli,
          snc_alq_cli: data?.snc_alq_cli,
          snc_cst_mix: data?.snc_cst_mix,
          snc_alq_mix: data?.snc_alq_mix,
          mxf_svc_rbc: data?.mxf_svc_rbc,
          pcn_svc_rbc: data?.pcn_svc_rbc,
          mxf_svc_rbcst: data?.mxf_svc_rbcst,
          pcn_svc_rbcst: data?.pcn_svc_rbcst,
          mxf_snc_rbc: data?.mxf_snc_rbc,
          pcn_snc_rbc: data?.pcn_snc_rbc,
          mxf_snc_rbcst: data?.mxf_snc_rbcst,
          pcn_snc_rbcst: data?.pcn_snc_rbcst,
          div_fecp: data?.div_fecp,
          pcn_fecp: data?.pcn_fecp,
          mxf_fecp: data?.mxf_fecp,
          div_fecp_st: data?.div_fecp_st,
          pcn_fecp_st: data?.pcn_fecp_st,
          mxf_fecp_st: data?.mxf_fecp_st,
        },
      });
      setIsSubmit(true);
    },
    [data, onSendLockProduct, cmfCliId],
  );

  const onConfirmBloqueio = data =>
    handleConfirmAlertHtml(
      {
        title: "Confirmar que irá utilizar a classificação alternativa ?",
        html: htmlText,
        icon: "question",
        textBtn: "Sim, confirmar!",
      },
      () => {
        onSend(data);
        dispatch(
          handleAddListBlockedProduct({
            pcn_cod_interno: data?.pcn_cod_interno,
          }),
        );
      },
    );

  const onConfirmDesbloqueio = data =>
    handleConfirmAlertHtml(
      {
        title:
          "Confirmar o uso da classificação do produto no cadastro Mixfiscal ?",
        html: htmlText,
        icon: "question",
        textBtn: "Sim, confirmar!",
      },
      () => onSendDesbloqueio(data),
    );

  useEffect(() => {
    if (!isLoading) {
      if (!isValidBloqueio) {
        reset({
          pcn_nome: data?.pcn_nome,
          pcn_cod_interno: data?.pcn_cod_interno,
          pcn_ean: ean,
        });
      }
      if (isValidBloqueio) {
        reset({
          pcn_nome: data?.pcn_nome,
          pcn_cod_interno: data?.pcn_cod_interno,
          pcn_ean: ean,
          usuario: infoProduct?.usuario,
          solicitado_bloqueio: infoProduct?.solicitado_bloqueio,
          dt_insert: dateFormat(infoProduct?.dt_insert),
          motivo_bloqueio: infoProduct?.motivo_bloqueio,
        });
      }
      return setIsValue(true);
    }
  }, [
    setValue,
    isSetValue,
    isValidBloqueio,
    reset,
    data,
    infoProduct,
    isLoading,
    ean,
  ]);

  const errorValue = (fild, isSelect) =>
    Swal.showValidationMessage(
      isSelect
        ? `O campo ${fild} devem estar preenchidos`
        : `O campo ${fild} não pode ficar em branco`,
    );

  const handleAjax = () =>
    MySwal.fire({
      title: isValidBloqueio
        ? "Desbloquear produto no cadastro de Classificação Alternativa."
        : "Classificação Alternativa",
      html: !isValidBloqueio ? (
        <BloqueioProduto
          ref={ref}
          data={data}
          watch={watch}
          control={control}
          handleSubmit={handleSubmit(onConfirmBloqueio)}
          errors={errors}
          setValue={setValue}
        />
      ) : (
        <DesbloqueioProduto
          ref={ref}
          data={infoProduct}
          watch={watch}
          control={control}
          handleSubmit={handleSubmit(onConfirmDesbloqueio)}
          errors={errors}
          setValue={setValue}
        />
      ),
      focusConfirm: false,
      showConfirmButton: false,
      showCloseButton: true,
      preConfirm: () => {
        const nome = document.getElementById("swal-input1")?.value;
        const codigo = document.getElementById("swal-input2")?.value;
        const ean = document.getElementById("swal-input3")?.value;
        const motivoBloqueio = document.getElementById("swal-input4")?.value;

        if (!nome) {
          errorValue("Nome do produto", true);
          return null;
        }
        if (!codigo) {
          errorValue("Código do produto");
          return null;
        }
        if (!ean) {
          errorValue("Ean do produto");
          return null;
        }
        if (!motivoBloqueio) {
          errorValue("Motivo para bloqueio", true);
          return null;
        }
      },
    });

  useEffect(() => {
    if (!isLoadingSolicitation && isSetSubmit) {
      revalidate();
      setIsSubmit(!isSetSubmit);
    }
  }, [isLoadingSolicitation, revalidate, setIsSubmit, isSetSubmit]);

  return (
    <>
      <div
        id={`informeBloqueioProduto_${keyItem}`}
        onMouseEnter={() => setPopoverOpen(true)}
        onMouseLeave={() => setPopoverOpen(false)}
        onClick={() => handleAjax()}
      >
        <Avatar
          className="mt-2"
          color={isValidBloqueio ? "light-danger" : "light-primary"}
          icon={<FontAwesomeIcon icon={faBandage} size="18" />}
        >
          <Button.Ripple className="btn-icon" outline color="danger">
            icon={<FontAwesomeIcon icon={faBandage} size="18" />}
          </Button.Ripple>
        </Avatar>
      </div>

      <Popover
        placement="left"
        target={`informeBloqueioProduto_${keyItem}`}
        isOpen={popoverOpen}
        toggle={() => setPopoverOpen(!popoverOpen)}
      >
        <PopoverHeader>
          {isValidBloqueio
            ? "Deseja desbloquear Classificação Alternativa Produto?"
            : "Solicitar Classificação Alternativa do Produto?"}
        </PopoverHeader>
        <PopoverBody className="text-center">
          {isValidBloqueio ? (
            <span>
              Clique para solicitar o desbloqueio da classificação alternativa.
            </span>
          ) : (
            <span>Clique aqui para confirmar a classificação alternativa</span>
          )}
        </PopoverBody>
      </Popover>
    </>
  );
};
