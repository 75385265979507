import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import DataTableDefault, { createTheme } from "react-data-table-component";
import ReactPaginate from "react-paginate";
import { AlertList } from "components/Structure/AlertList";
import { Form, Spinner, Table } from "reactstrap";
import { Check, ChevronDown } from "react-feather";

import { useSkin } from "@hooks/useSkin";
import * as S from "./styles";

const paginationComponentOptions = {
  rowsPerPageText: "Items por página",
  rangeSeparatorText: "de",
  selectAllRowsItem: true,
  selectAllRowsItemText: "Todos",
};

createTheme("dark", {
  background: {
    default: "transparent",
  },
});

const CustomPagination = ({
  totalPages,
  handlePageChange,
  page: pageItem,
  paginationPosition,
}) => (
  <ReactPaginate
    nextLabel=""
    breakLabel="..."
    previousLabel=""
    pageCount={totalPages}
    activeClassName="active"
    breakClassName="page-item"
    pageClassName={"page-item"}
    breakLinkClassName="page-link"
    nextLinkClassName={"page-link"}
    pageLinkClassName={"page-link"}
    nextClassName={"page-item next"}
    previousLinkClassName={"page-link"}
    previousClassName={"page-item prev"}
    onPageChange={({ selected }) =>
      handlePageChange && handlePageChange(selected === 0 ? 1 : selected + 1)
    }
    forcePage={pageItem !== 0 ? pageItem - 1 : 0}
    containerClassName={`pagination react-paginate ${paginationPosition} p-1 mb-0`}
  />
);

const NoData = ({ titleError, isSuccess = false }) => (
  <AlertList
    isSuccess={isSuccess}
    title="Informativo"
    description={titleError || "Ainda não há dados cadastrados nessa seção."}
  />
);

const ProgressPage = () => (
  <div className="text-center pt-4 pb-4">
    <Spinner />
    <h4>Carregando dados....</h4>
  </div>
);

const CellRow = ({ column, item }) => {
  if (column.cell) {
    return column.cell(item);
  }

  return item[column.name];
};

export const DataTable = ({
  isLoading,
  totalDocs,
  totalPages,
  page = 1,
  data = [],
  pagination = true,
  isError = false,
  paginationServer = true,
  handlePageChange = () => {},
  handlePerRowsChange = () => {},
  handleInputPagination = () => {},
  onSort = null,
  titleError,
  isSuccessNotData = false,
  paginationPosition = `justify-content-end`,
  type = "full",
  inputPagination = false,
  marginInput = false,
  ...props
}) => {
  const { handleSubmit, control, watch } = useForm();
  const [focus, setFocus] = useState(false);

  const newPageWatch = watch("newPage");

  const onChangePageByInput = data => {
    if (data?.newPage > totalPages || data?.newPage < 1) return;
    setFocus(false);

    handleInputPagination(data?.newPage);
  };

  useEffect(() => {
    if (newPageWatch === "") {
      setFocus(false);
    }
  }, [newPageWatch]);

  //se o valor do input for diferente de undefined ou string vazia e o valor é diferente da page atual e é maior ou igual a 1 e menor ou igual ao total de pages
  const conditionsChangePage =
    newPageWatch !== undefined &&
    newPageWatch !== "" &&
    newPageWatch !== page &&
    newPageWatch >= 1 &&
    newPageWatch <= totalPages;

  const { skin } = useSkin();

  if (type === "simple") {
    const columns = props.columns ?? [];
    return (
      <Table responsive bordered>
        <thead>
          <tr>
            {columns?.map((item, key) => (
              <th
                className={item.className}
                key={`column-simple-${key}-${item.name}`}
              >
                {item.title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {isLoading && (
            <tr>
              <td colSpan={columns.length}>
                <ProgressPage />
              </td>
            </tr>
          )}
          {!isLoading &&
            data?.length > 0 &&
            data?.map(dataItem => (
              <tr key={dataItem.id}>
                {columns.map((column, key) => (
                  <td key={`column-td-simple-${key}-${column.name}`}>
                    <div className={column.className}>
                      <CellRow column={column} item={dataItem} />
                    </div>
                  </td>
                ))}
              </tr>
            ))}
        </tbody>
      </Table>
    );
  }

  return (
    <DataTableDefault
      pagination={pagination}
      paginationServer={paginationServer}
      data={data}
      onSort={onSort}
      theme={skin === "dark" ? "dark" : "default"}
      sortIcon={onSort && <ChevronDown />}
      paginationDefaultPage={page}
      progressPending={isLoading}
      paginationComponentOptions={paginationComponentOptions}
      paginationTotalRows={totalDocs}
      onChangeRowsPerPage={handlePerRowsChange}
      paginationComponent={extraProps =>
        pagination && (
          <div className="d-flex align-items-center justify-content-end mt-1 gap-1">
            <CustomPagination
              paginationPosition={paginationPosition}
              totalPages={totalPages}
              handlePageChange={handlePageChange}
              page={page}
              className="me-1"
              {...extraProps}
            />
            {inputPagination && (
              <>
                <label className="ms-1">Ir para a página</label>

                <S.DivMaxW
                  className={marginInput && !conditionsChangePage && `me-1`}
                >
                  <Form onSubmit={handleSubmit(onChangePageByInput)}>
                    <S.InputChangePage
                      name="newPage"
                      control={control}
                      onClick={() => setFocus(true)}
                      autoFocus={focus}
                    />
                  </Form>
                </S.DivMaxW>
                {conditionsChangePage && (
                  <S.BtnConfirm
                    onClick={handleSubmit(onChangePageByInput)}
                    className={`bg-light-success rounded-2 d-flex align-items-center justify-content-center cursor-pointer ${
                      inputPagination && "me-1"
                    }`}
                  >
                    <Check size={15} />
                  </S.BtnConfirm>
                )}
              </>
            )}
          </div>
        )
      }
      noDataComponent={
        isError ? (
          <div>{titleError ? titleError : "Nenhum dados para listar"}</div>
        ) : (
          !isLoading &&
          !data.length && (
            <NoData isSuccess={isSuccessNotData} titleError={titleError} />
          )
        )
      }
      progressComponent={<ProgressPage />}
      {...props}
    />
  );
};
