import React, { useMemo } from "react";

import { useNavigate } from "react-router-dom";
import { CardFooter, Col, Button } from "reactstrap";

export const Actions = ({ columns, dataItem, handleClick }) => {
  const navigate = useNavigate();
  const actionItem = useMemo(() => {
    const detal = (columns || [])
      .filter(item => item?.detailUrl || item?.isModal)
      .map(item => {
        const fields = {};
        fields.value = dataItem[item?.sortField];
        if (item?.detailUrl) {
          fields.url = `${item?.detailUrl}${dataItem[item?.sortField]}`;
        }
        if (item?.isModal) {
          fields.isModal = item?.isModal;
        }
        return { ...fields };
      });

    return {
      detail: detal,
    };
  }, [columns, dataItem]);

  const handleAction = item => {
    if (item?.url) {
      return navigate(item?.url);
    }

    if (item?.isModal) {
      return handleClick(item?.value);
    }
  };

  return (
    <CardFooter>
      <Col className="d-flex justify-content-center">
        {actionItem?.detail && (
          <Button
            onClick={() => handleAction(actionItem?.detail[0])}
            color="primary"
            size="sm"
            outline
            className="me-1"
          >
            DETALHE
          </Button>
        )}
      </Col>
    </CardFooter>
  );
};
