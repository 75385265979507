import React from "react";
import { useNavigate } from "react-router-dom";
import { MdOpenInNew, MdEdit, MdRemoveCircleOutline } from "react-icons/md";
import { IoAdd } from "react-icons/io5";
import { LoadCircle } from "components/Structure/Load/load";
import * as S from "./button.styles";

export const ButtonOpenNewTag = ({
  toLink,
  openNewAba = false,
  onClick = () => {},
  ...props
}) => {
  const navigate = useNavigate();

  const handleClick = event => {
    if (toLink && openNewAba) {
      return window.open(toLink, "_blank");
    }

    if (toLink) {
      return navigate(toLink);
    }

    return onClick && onClick(event);
  };

  return (
    <S.ButtonOpenNewTag type="button" {...props} onClick={handleClick}>
      <MdOpenInNew color="#a4a0a0" size={28} />
    </S.ButtonOpenNewTag>
  );
};

export const Button = ({
  toLink,
  goBack,
  onClick,
  label,
  children,
  isLoading,
  ...props
}) => {
  const navigate = useNavigate();

  const handleClick = event => {
    if (toLink) {
      return navigate.push(toLink);
    }

    if (goBack) {
      return navigate(-1);
    }

    return onClick && onClick(event);
  };

  return (
    <S.Button onClick={handleClick} isDisabled={isLoading} {...props}>
      {isLoading && (
        <S.LoadingContainer>
          <LoadCircle width="20px" height="20px" />
        </S.LoadingContainer>
      )}
      {label}
      {children}
    </S.Button>
  );
};

export const ButtonEditing = props => (
  <S.ButtonOpenNewTag type="button" {...props}>
    <MdEdit color="#a4a0a0" size={28} />
  </S.ButtonOpenNewTag>
);

export const ButtonIconRemove = props => (
  <S.ButtonOpenNewTag type="button" {...props}>
    <MdRemoveCircleOutline color="#a4a0a0" size={28} />
  </S.ButtonOpenNewTag>
);

export const ButtonAdd = ({ children, ...props }) => (
  <S.ButtonAdd {...props}>{children ?? <IoAdd />}</S.ButtonAdd>
);

// eslint-disable-next-line prefer-destructuring
export const ButtonContainerIcon = S.ButtonContainerIcon;
