import styled from "styled-components";

import { Button } from "reactstrap";

export const TotalTitle = styled.h1`
  margin-top: 5px;
`;

export const CustomBtn = styled(Button)`
  padding: 0.5rem 0.5rem !important;
  position: relative;
  bottom: 0.7rem;
`;
