import { useFetchMixAPi1, useMixApi1Toast } from "hooks";
import { getURLWithParams } from "utils/context";

export const useBloqueioProduto = () => {
  const { isLoading, onSend } = useMixApi1Toast();
  const onSendLockProduct = data => {
    onSend({
      endpoint: "relatorio-produtos/bloquear-produto-cliente",
      messageSuccess: `Bloqueio realizado com sucesso`,
      messageFail: "Falha ao bloquear produto",
      data,
      type: "POST",
    });
  };
  const onSendUnlockProduct = data => {
    onSend({
      endpoint: "relatorio-produtos/desbloquear-produto-cliente",
      messageSuccess: `Desbloqueio realizado com sucesso`,
      messageFail: "Falha ao desbloquear produto",
      data,
      type: "PUT",
    });
  };
  return {
    onSendLockProduct,
    onSendUnlockProduct,
    isLoading,
  };
};

export const useGetProductsClient = id => {
  const { data, isLoading } = useFetchMixAPi1(
    getURLWithParams(`/relatorio-produtos/produtos-cliente/${id}`),
  );
  return { data, isLoading };
};

export const useGetProdutoBloqueado = params =>
  useFetchMixAPi1(
    params.cmf_cli_id != null && params.pcn_cod_interno != null
      ? getURLWithParams(
          `/relatorio-produtos/produto-bloqueado-cliente`,
          params,
        )
      : null,
  );
export const useGetHistoricoProduto = params =>
  useFetchMixAPi1(
    getURLWithParams("/relatorio-produtos/historico-produto", params),
  );

export const useStatusProduto = params =>
  useFetchMixAPi1(
    getURLWithParams("/relatorio-produtos/status-produto", params),
  );

export const useAtualizaStatusProduto = () => {
  const { isLoading, onSend } = useMixApi1Toast();
  const onSendStatus = data => {
    onSend({
      endpoint: "/relatorio-produtos/atualiza-status-produto",
      messageSuccess: `Status do produto atualizado!`,
      messageFail: "Falha ao alterar status do produto!",
      data,
      type: "POST",
    });
  };

  return {
    onSendStatus,
    isLoading,
  };
};
