import { useState, useCallback, useEffect } from "react";
import {
  useFetchAuthenticate,
  useAPIAuthenticate,
  useToast,
  useAPIAuthenticateToast,
  usePostFetchAuthenticate,
} from "hooks";
import { getURLWithParams } from "utils/context";
import { validatePassword } from "utils/utils";
import { USER_TYPE } from "constants/Types/index";

const CredentialsUser = {
  Atendente: "attendant",
  Administrador: "admin",
  Gerente: "manager",
  Supervisor: "financial",
  dev: "dev",
  root: "root",
};

export const useUsers = (params = {}) =>
  useFetchAuthenticate(params ? getURLWithParams("/users", params) : null);

export const useUsersU = (params = {}) =>
  useFetchAuthenticate(params ? getURLWithParams("/users", params) : null);

export const useUser = id => useFetchAuthenticate(id ? `/user/${id}` : "/user");

export const useUserList = params =>
  usePostFetchAuthenticate(params ? "users/ids" : null, params);

export const useUserRemove = () => {
  const { isLoading, onSend } = useAPIAuthenticateToast();

  const onRemove = id =>
    onSend({
      endpoint: `/user/${id}`,
      messageSuccess: "Usuario foi deletado com sucesso!",
      messageFail: "Não foi possível deletar o usuário!",
      type: "DELETE",
    });

  return {
    onRemove,
    isLoading,
  };
};

export const useWalletUser = (id, params = {}) =>
  useFetchAuthenticate(
    id ? getURLWithParams(`/user/wallet/${id}`, params) : null,
  );

export const useUserWallet = () => {
  const api = useAPIAuthenticate();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const { addToast } = useToast();

  const fetch = useCallback(
    id => {
      setLoading(true);
      const url = id || ``;
      const fetchData = async () => {
        try {
          const {
            data: { wallet },
          } = await api.get(`/user/wallet/${url}`);

          if (wallet.docs === 0) {
            addToast({
              type: "warning",
              align: "top",
              title: "Ops...",
              description: "Nehuma Carteira de Cliente encontrada",
            });
          }

          setData(wallet.docs);
          setLoading(false);
        } catch (e) {
          setLoading(false);
          console.log(e.messagge);
        }
      };
      fetchData(url);
    },
    [api, addToast],
  );

  return {
    data,
    fetch,
    isLoading: loading,
  };
};

export const useUserGet = ({ id } = {}) => {
  const api = useAPIAuthenticate();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(``);
  const fetch = useCallback(
    (params = {}) => {
      setLoading(true);
      setIsError(`loading`);
      const url = id ?? params.id ?? ``;
      const fetchData = async () => {
        try {
          if (url === "") {
            return false;
          }
          const {
            data: { user },
          } = await api.get(`/user/${url}`);
          setData(user);
        } catch (e) {
          console.log(e.messagge);
          setIsError(``);
        } finally {
          setLoading(false);
        }
      };
      fetchData();
    },
    [api, id],
  );

  useEffect(() => {
    fetch();
  }, [fetch]);

  return {
    data,
    fetch,
    isError,
    isLoading: loading,
  };
};

export const useUserCrud = item => {
  const api = useAPIAuthenticate();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(``);

  const { addToast } = useToast();

  const fetch = useCallback(
    (value = {}, tableData) => {
      setLoading(true);

      const validation = validatePassword(value?.password);

      if (!validation && (!value?._id || value?.password)) {
        return addToast({
          title: "Usuário error senha",
          type: "error",
          description: `Precisa conter 8 caracteres, letras minúsculas,
                letras maiúsculas, números e caracteres especiais.`,
        });
      }

      if (value.password !== value.confirmPassword) {
        return addToast({
          title: "Usuário",
          type: "error",
          description: `Os campos de senha não coincidem`,
        });
      }

      const fetchData = async () => {
        try {
          if (item.id) {
            const newTableData = JSON.parse(JSON.stringify(tableData)).map(
              tableItem => {
                const newTableItem = JSON.parse(JSON.stringify(tableItem));
                delete newTableItem._id;
                return newTableItem;
              },
            );
            try {
              const fields = {
                ...data,
                officeHour: {
                  afterHoursAccess: Boolean(value.officeHour),
                  entryTime: value.enteringTime,
                  departureTime: value.leavingTime,
                },
                permissions: {
                  export: {
                    xml: !!value.export_xml,
                    pdf: !!value.export_pdf,
                    csv: !!value.export_csv,
                    txt: !!value.export_txt,
                  },
                  import: {
                    xml: !!value.import_xml,
                    pdf: !!value.import_pdf,
                    csv: !!value.import_csv,
                    txt: !!value.import_txt,
                  },
                },
                is_mix: value.is_mix || false,
                contractService: value.hireService,
                isActive: Boolean(value.is_active),
                whatsAppNumber: value.whatsAppNumber,
                acceptInvite: value.inviteThroughEmail,
                name: value.name,
                surname: value.surname,
                email: value.email,
                responsibility: value.role,
                type: CredentialsUser[value.role],
                groupPermissions: newTableData,
              };

              if ((item.id && value.password) || value.password) {
                fields.password = value.password;
              }

              const { data: updatedData } = await api.put(
                `/user/${item.id}`,
                fields,
              );

              setData(updatedData.user);
              addToast({
                title: "Usuário",
                type: "success",
                description: "Usuário atualizado com sucesso!",
              });
            } catch (err) {
              addToast({
                title: "Usuário",
                type: "error",
                description: `Ocorreu o seguinte erro: ${err.message}`,
              });
              setIsError(err);
            }
          } else {
            console.log(value);
            const newTableData = JSON.parse(JSON.stringify(tableData)).map(
              tableItem => {
                const newTableItem = JSON.parse(JSON.stringify(tableItem));
                delete newTableItem._id;
                console.log(newTableItem);
                return newTableItem;
              },
            );
            try {
              await api.post(`/user`, {
                officeHour: {
                  afterHoursAccess: Boolean(value.officeHour),
                  entryTime: value.enteringTime,
                  departureTime: value.leavingTime,
                },
                permissions: {
                  export: {
                    xml: !!value.export_xml,
                    pdf: !!value.export_pdf,
                    csv: !!value.export_csv,
                    txt: !!value.export_txt,
                  },
                  import: {
                    xml: !!value.import_xml,
                    pdf: !!value.import_pdf,
                    csv: !!value.import_csv,
                    txt: !!value.import_txt,
                  },
                },
                is_mix: value.is_mix || false,
                contractService: value.hireService,
                isActive: Boolean(value.is_active.value),
                acceptInvite: value.inviteThroughEmail,
                whatsAppNumber: value.whatsAppNumber,
                name: value.name,
                surname: value.surname,
                email: value.email,
                responsibility: value.role,
                type: CredentialsUser[value.role],
                password: value.password,
                groupPermissions: newTableData,
              });
              addToast({
                title: "Usuário",
                type: "success",
                description: "Usuário criado com sucesso!",
              });
            } catch (err) {
              if (value.password === "") {
                addToast({
                  title: "Usuário",
                  type: "error",
                  description: `Preencha os campos de Senha e Confirmar Senha!`,
                });
              } else {
                setIsError(err);
                addToast({
                  title: "Usuário",
                  type: "error",
                  description: `Ocorreu o seguinte erro: ${err.message}`,
                });
              }
            }
          }
        } catch (e) {
          setIsError(e);
          setLoading(false);
          console.log(e.messagge);
          setIsError(``);
        }
      };
      fetchData();
    },
    [api, addToast, item, data],
  );
  return {
    data,
    fetch,
    isError,
    isLoading: loading,
  };
};

export const useUsersAssessores = () =>
  useFetchAuthenticate("/user/list/assessor");
export const useUserCrud2 = item => {
  const api = useAPIAuthenticate();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(``);

  const { addToast } = useToast();

  const fetch = useCallback(
    (value = {}) => {
      setLoading(true);

      const validation = validatePassword(value?.password);

      if (
        value?.password?.length > 0 &&
        !validation &&
        (!value?._id || value?.password)
      ) {
        return addToast({
          title: "Usuário error senha",
          type: "error",
          description: `Precisa conter 8 caracteres, letras minúsculas,
                  letras maiúsculas, números e caracteres especiais.`,
        });
      }

      if (
        value?.password?.length > 0 &&
        value?.password !== undefined &&
        value.password !== value?.confirmPassword
      ) {
        return addToast({
          title: "Usuário",
          type: "error",
          description: `Os campos de senha não coincidem`,
        });
      }

      const fetchData = async () => {
        try {
          if (item?.id) {
            try {
              const fields = {
                ...data,
                officeHour: {
                  afterHoursAccess: Boolean(value?.officeHour),
                  entryTime: value?.enteringTime,
                  departureTime: value?.leavingTime,
                },
                permissions: {
                  export: {
                    xml: !!value?.export_xml,
                    pdf: !!value?.export_pdf,
                    csv: !!value?.export_csv,
                    txt: !!value?.export_txt,
                  },
                  import: {
                    xml: !!value?.import_xml,
                    pdf: !!value?.import_pdf,
                    csv: !!value?.import_csv,
                    txt: !!value?.import_txt,
                  },
                },
                is_mix: value?.is_mix || false,
                contractService: value?.hireService,
                isActive: Boolean(value?.isActive),
                whatsAppNumber: value?.whatsAppNumber,
                acceptInvite: value?.inviteThroughEmail,
                name: value?.name,
                surname: value?.lastName || value?.surname,
                email: value?.email,
                responsibility: value?.role,
                type: USER_TYPE[value?.role],
                groupPermissions: value?.groupPermissions,
              };

              if ((item?.id && value?.password) || value?.password) {
                fields.password = value?.password;
              }

              const { data: updatedData } = await api.put(
                `/user/${item?.id}`,
                fields,
              );

              setData(updatedData.user);
              addToast({
                title: "Usuário",
                type: "success",
                description: "Usuário atualizado com sucesso!",
              });
            } catch (err) {
              addToast({
                title: "Usuário",
                type: "error",
                description: `Ocorreu o seguinte erro: ${err?.message}`,
              });
              setIsError(err);
            }
          } else {
            try {
              await api.post(`/user`, {
                officeHour: {
                  afterHoursAccess: Boolean(value.officeHour),
                  entryTime: value.enteringTime,
                  departureTime: value.leavingTime,
                },
                permissions: {
                  export: {
                    xml: !!value.export_xml,
                    pdf: !!value.export_pdf,
                    csv: !!value.export_csv,
                    txt: !!value.export_txt,
                  },
                  import: {
                    xml: !!value.import_xml,
                    pdf: !!value.import_pdf,
                    csv: !!value.import_csv,
                    txt: !!value.import_txt,
                  },
                },
                is_mix: value.is_mix || false,
                contractService: value.hireService,
                isActive: Boolean(value.isActive),
                acceptInvite: value.inviteThroughEmail,
                whatsAppNumber: value.whatsAppNumber,
                name: value.name,
                surname: value.surname,
                email: value.email,
                responsibility: value.role,
                type: USER_TYPE[value.role],
                password: value.password,
                groupPermissions: value?.groupPermissions,
              });
              addToast({
                title: "Usuário",
                type: "success",
                description: "Usuário criado com sucesso!",
              });
            } catch (err) {
              if (value?.password === "") {
                addToast({
                  title: "Usuário",
                  type: "error",
                  description: `Preencha os campos de Senha e Confirmar Senha!`,
                });
              } else {
                setIsError(err);
                addToast({
                  title: "Usuário",
                  type: "error",
                  description: `Ocorreu o seguinte erro: ${err?.message}`,
                });
              }
            }
          }
        } catch (e) {
          setIsError(e);
          setLoading(false);
          console.log(e.messagge);
          setIsError(``);
        }
      };
      fetchData();
    },
    [api, addToast, item, data],
  );
  return {
    data,
    fetch,
    isError,
    isLoading: loading,
  };
};
