import { useState, useCallback } from "react";
import { useAPILinkMix, useFetchNfe } from "hooks";
import { getURLWithParams } from "utils/context";

export const useBuscarProductsIntegracaoApi = source => {
  const api = useAPILinkMix();
  const [dataItems, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetch = useCallback(
    (params = {}) => {
      setLoading(true);
      api
        .post("/busca-produtos", params, {
          cancelToken: source?.token,
        })
        .then(({ data }) => {
          setData(data);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [source?.token, api],
  );

  return {
    isLoading: loading,
    data: dataItems,
    fetch,
  };
};

export const useMixIntegrationEscriturationTotalsCfop = (params = {}) =>
  useFetchNfe(
    getURLWithParams("/mix-integration/escriturarion-totals-cfop", params),
  );

export const useMixIntegrationEscriturationTotalsCfopXml = (params = {}) =>
  useFetchNfe(
    getURLWithParams("/mix-integration/escriturarion-totals-cfop-xmls", params),
  );
