import React from "react";

import { Button } from "reactstrap";

import { TextField, Form } from "components/Form";

export const BloqueioProduto = ({ handleSubmit, control, errors }) => (
  <Form onSubmit={handleSubmit}>
    <TextField
      control={control}
      id="swal-input1"
      name="pcn_nome"
      label="Produto"
      disabled
    />
    <TextField
      control={control}
      id="swal-input2"
      name="pcn_cod_interno"
      label="Código do produto"
      disabled
    />
    <TextField
      control={control}
      id="swal-input3"
      name="pcn_ean"
      label="EAN"
      disabled
    />
    <TextField
      type="textarea"
      control={control}
      id="swal-input4"
      name="motivo_bloqueio"
      label="Lembrete p/ recorda o fundamento legal"
      error={errors?.motivo_bloqueio}
      helperText='O campo "Lembrete p/ recorda o fundamento legal" não pode ficar em branco'
      required
    />
    <Button.Ripple className="mt-1" type="submit" color="primary">
      Solicitar
    </Button.Ripple>
  </Form>
);

export const DesbloqueioProduto = ({ handleSubmit, control, data }) => (
  <Form onSubmit={handleSubmit}>
    <TextField
      control={control}
      id="swal-input2"
      name="pcn_cod_interno"
      label="Código do produto"
      disabled
    />
    <TextField
      control={control}
      id="swal-input1"
      name="pcn_nome"
      label="Produto"
      disabled
    />
    <TextField
      control={control}
      id="swal-input3"
      name="pcn_ean"
      label="EAN"
      disabled
    />
    <TextField
      control={control}
      name="usuario"
      label="Solicitado por"
      disabled
    />

    <TextField
      control={control}
      name="dt_insert"
      label="Data do Solicitação"
      disabled
    />
    <TextField
      type="textarea"
      control={control}
      name="motivo_bloqueio"
      label="Motivo do Classificação alternativa do produto"
      disabled
    />
    <TextField
      control={control}
      name="solicitado_bloqueio"
      label="Classificação Alternativa"
      disabled
      defaultChecked={data?.solicitado_bloqueio}
      type="checkbox"
    />
    <Button.Ripple className="mt-1" type="submit" color="primary">
      Solicitar
    </Button.Ripple>
  </Form>
);
