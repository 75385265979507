import styled from "styled-components";

// type ContainerProps = {
//   type: "simple" | "advanced";
// }

export const Container = styled.div`
  width: fit-content;
  padding: ${props => (props.type === "simple" ? "4px 36px" : "4px 16px")};
  border-radius: 16px;
  background-color: ${props =>
    props.type === "simple" ? "#E5E5E5" : "#1B66B0"};
  transition: all 0.2s;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;

  &:hover {
    background-color: ${props =>
      props.type === "simple" ? "#BABABA" : "#1B66B0"};
  }

  .clickable {
    cursor: pointer;
  }

  p {
    color: ${props => (props.type === "simple" ? "#596778" : "#FFF")};
    font-weight: bold;
    font-size: 14px;
  }
`;
