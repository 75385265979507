import { useEffect, useRef } from "react";
import axios from "axios";
import { useAuth } from "context/AuthContext";
import useSWR from "swr";

const MIX_API = "https://api.mixfiscal.com.br/diagnostico-analytics/v1";

export const useDiagnosis = () => {
  const onRejected = error => {
    return Promise.reject(error);
  };

  const apiRef = useRef(
    axios.create({
      baseURL: MIX_API,
    }),
  );

  apiRef.current.interceptors.response.use(response => response, onRejected);

  return apiRef.current;
};

export const useFetchDiagnosis = (url, axiosConfigs, config) => {
  const api = useDiagnosis();
  const { tokenCompanies, revalidateAll, setRevalidateAll } = useAuth();

  const { data, error, mutate, revalidate, isValidating } = useSWR(
    url,
    async urlReturn => {
      const response = await api.get(urlReturn, axiosConfigs);
      return response.data;
    },
    config,
  );

  // Revalida todas as requisicoes para carregar os dados corretamente!
  useEffect(() => {
    if (revalidateAll?.length) {
      revalidate();
      setRevalidateAll();
    }
  }, [tokenCompanies, revalidate, revalidateAll, setRevalidateAll]);

  return {
    data,
    error,
    isError: !!error,
    isLoading: (!error && !data && String(data) !== "") || isValidating,
    isValidating,
    mutate,
    revalidate,
  };
};
