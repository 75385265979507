import React from "react";

import Icon from "components/IconExporter";
import * as S from "./cardLocal.styles";

export const Card = ({
  title,
  headerColor = "#256925",
  iconName,
  children,
  action,
}) => (
  <S.Container>
    <S.Header color={headerColor}>
      <S.HeaderContent>
        <Icon name={iconName} size={18} />
        <p>{title}</p>
      </S.HeaderContent>
      <S.HeaderAction>{action}</S.HeaderAction>
    </S.Header>
    <S.Content>{children}</S.Content>
  </S.Container>
);
