import { createSlice } from "@reduxjs/toolkit";
import { GetPermissionsValues } from "components/Configs/AccessGroups/Form/GroupForm/Forms/Permissions/Table/header";

export const initialAccessGroups = {
  list: [],
  params: {
    groupName: "",
    page: 1,
    limit: 20,
  },
  totalPages: 0,
  form: {},
  copyPerms: {
    label: "",
    value: "",
  },
  companies: [],
  permissions: [],
  permValues: [],
};

const initialState = () => {
  return initialAccessGroups;
};

export const accessGroupsSlice = createSlice({
  name: "accessGroups",
  initialState,
  reducers: {
    handleSetList: (state, { payload }) => {
      state.list = [...(payload.list ?? [])];
      state.totalPages = payload.totalPages;
    },
    handleSetPage: (state, { payload }) => {
      state.page = payload.page;
    },
    handleSetPageAndLimit: (state, { payload }) => {
      if (payload.page) {
        state.params.page = payload.page;
      }
      if (payload.limit) {
        state.params.limit = payload.limit;
      }
    },
    handleParamsLimit: (state, { payload }) => {
      state.params.limit = payload.limit;
    },
    handleSubmitAccessGroup: (state, { payload }) => {
      state.form = payload;
    },
    handleSetCopyPerms: (state, { payload }) => {
      state.copyPerms = payload;
    },
    handleListPermissons: (state, { payload }) => {
      state.permissions = payload?.perms;
    },
    handleSetPermType: (state, { payload }) => {
      state.permValues = payload;
    },

    handleSetStoreData: (state, { payload }) => {
      state.companies = payload.storeData;
    },

    handleChangeRulesParams: (state, { payload }) => {
      const moduleId = payload?.moduleId;
      const rules = payload?.rules;

      const modules = [...state.permValues];

      const others = (modules || []).filter(
        item => item?.moduleId !== moduleId,
      );

      const selected = (modules || []).find(
        item => item?.moduleId === moduleId,
      );
      const moduleUpdate = {
        ...selected,
        rules,
      };

      const newList = [moduleUpdate, ...others];

      state.permValues = newList;
    },
    handleChildrenModuleUpdate: (state, { payload }) => {
      const modules = [...state.permValues];

      const module = payload?.module;
      const rules = payload?.rules;

      const fatherId = module?.fatherId;

      const moduleId = payload?.moduleId;

      const others = (modules || []).filter(item => item?._id !== moduleId);

      const selected = (modules || []).find(item => item?._id === moduleId);

      selected.moduleId = fatherId;

      const moduleUpdate = {
        ...selected,
        rules,
      };

      const newList = [moduleUpdate, ...others];

      state.permValues = newList;
    },

    handleUpdataChildrenPerm: (state, { payload }) => {
      const moduleId = payload?.moduleId;
      const value = payload?.value;

      const permValues = state.permValues;

      const index = permValues.findIndex(
        module => module.moduleId === moduleId,
      );

      const others = permValues.filter(item => item?.moduleId !== moduleId);

      const selected = permValues[index];

      const item = { ...selected };

      const rules = GetPermissionsValues(value);

      item.rules = rules;

      const newList = [item, ...others];

      state.permValues = newList;
    },
    handleFilter(state, { payload }) {
      const field = payload?.field;
      state.params[field] = payload?.value;
      return state;
    },
  },
});

export const {
  handleSetList,
  handleSetPage,
  handleSetPageAndLimit,
  handleParamsLimit,
  handleSubmitAccessGroup,
  handleListPermissons,
  handleSetPermType,
  handleChangeRulesParams,
  handleChildrenModuleUpdate,
  handleUpdataChildrenPerm,
  handleSetCopyPerms,
  handleSetStoreData,
  handleFilter,
} = accessGroupsSlice.actions;

export default accessGroupsSlice.reducer;
