import {
  useFetchAuthenticate,
  useFetchNfe,
  useAPIAuthenticateToast,
  useAPINFE2Toast,
} from "hooks";

import { getURLWithParams } from "utils/context";

export const useReports = (params = {}) =>
  useFetchAuthenticate(getURLWithParams("/reports", params));

export const useReportsCfopCsts = (params = {}) =>
  useFetchNfe(getURLWithParams("/reports/cfops-csts", params));

export const useReportsCfops = (params = {}) =>
  useFetchNfe(getURLWithParams("/reports/cfops", params));

export const useReportsCfopsDash = (params = {}) =>
  useFetchNfe(getURLWithParams("/reports/cfops/dash", params));

export const useReportsTotalsModelList = (params = {}) =>
  useFetchNfe(getURLWithParams("/cfops/cfops-empresas", params));

export const useReportsCfopsForm = () => {
  const { isLoading, onSend } = useAPINFE2Toast();

  const onEditCfop = data =>
    onSend({
      endpoint: "/cfops/cfops-empresas",
      messageSuccess: "CFOP atualizado com sucesso!",
      messageFail: "Falha ao tentar atualizar CFOP!",
      data,
      type: "PUT",
    });

  return {
    onEditCfop,
    isLoading,
  };
};

export const useReportsSkus = (params = {}) =>
  useFetchNfe(getURLWithParams("/reports/products-abc", params));

export const useDownloadSkus = (clicked, params = {}) =>
  useFetchNfe(
    clicked ? getURLWithParams("/reports/skus/download", params) : null,
  );

export const useReportsDocumentosFiscais = (params = {}) =>
  useFetchNfe(getURLWithParams("/reports/documentos-fiscais", params));

export const useReportsDocumentosFiscaisXMLActions = () => {
  const { isLoading, onSend } = useAPIAuthenticateToast();

  const onCSVGenerate = data =>
    onSend({
      endpoint: "/action/gerar-csv-xmls",
      messageSuccess: "Arquivo esta sendo processado favor verificar o email!",
      messageFail: "Falha ao tentar gerar CSV!",
      data,
    });

  return {
    onCSVGenerate,
    isLoading,
  };
};
