import React from "react";
import { Icon } from "components/Structure/IconComponent";
import { Controller } from "react-hook-form";
import { Label } from "reactstrap";
import ReactSelect, { components } from "react-select";
import { selectThemeColors } from "@utils";

import { SelectAsync } from "./asyncSelect";

import * as S from "./styles";

const DropdownIndicator = props => (
  <components.DropdownIndicator {...props}>
    <Icon name="dropdown" color="#8C8B8B" size={12} />
  </components.DropdownIndicator>
);

const NoOptionsMessage = props => (
  <components.NoOptionsMessage {...props}>
    <span style={{ fontSize: "1rem" }}>Sem dados</span>
  </components.NoOptionsMessage>
);

const MultiValue = ({ index, getValue, ...props }) => (
  <components.MultiValue {...props} />
);

export const Select = ({
  control,
  label,
  name,
  defaultValue,
  data,
  isDisabled,
  labelType,
  placeholder = "Selecione",
  isLoading,
  components: componentProps = {},
  isSearchable,
  required,
  minWidth,
  borderBottomRightRadius,
  borderTopRightRadius,
  value: valueDefault,
  helperText,
  width,
  error,
  maxMenuHeight,
  ...rest
}) =>
  control ? (
    <S.Select
      type={labelType}
      minWidth={minWidth}
      borderBottomRightRadius={borderBottomRightRadius}
      borderTopRightRadius={borderTopRightRadius}
    >
      {label && (
        <Label className="form-label">
          {label}
          {required && <span className="text-danger">*</span>}
        </Label>
      )}
      {isLoading ? (
        <S.SelectLoading>
          <span>Carregando...</span>
        </S.SelectLoading>
      ) : (
        <Controller
          rules={{ required }}
          control={control}
          name={name}
          defaultValue={defaultValue}
          render={({ field, value, defaultValue: defaultValue2 }) => (
            <S.InputFieldContainer width={width}>
              <ReactSelect
                onMenuOpen
                isSearchable={isSearchable}
                theme={selectThemeColors}
                styles={{
                  control: S.control,
                  indicatorSeparator: S.indicatorSeparator,
                  valueContainer: S.valueContainer,
                  placeholder: S.placeholder,
                  singleValue: S.singleValue,
                  menu: S.menu,
                }}
                components={{
                  DropdownIndicator,
                  NoOptionsMessage,
                  ...componentProps,
                }}
                options={data}
                placeholder={placeholder}
                isDisabled={isDisabled}
                className="react-select"
                classNamePrefix="select"
                defaultValue={defaultValue2}
                {...field}
                {...rest}
              />
              <S.InputFieldHelperText error={!value && error}>
                {helperText}
              </S.InputFieldHelperText>
            </S.InputFieldContainer>
          )}
        />
      )}
    </S.Select>
  ) : (
    <S.Select
      type={labelType}
      minWidth={minWidth}
      borderBottomRightRadius={borderBottomRightRadius}
      borderTopRightRadius={borderTopRightRadius}
    >
      {label && (
        <Label className="form-label">
          {label}
          {required && <span className="text-danger">*</span>}
        </Label>
      )}
      <ReactSelect
        onMenuOpen
        isSearchable={isSearchable}
        theme={selectThemeColors}
        styles={{
          control: S.control,
          indicatorSeparator: S.indicatorSeparator,
          valueContainer: S.valueContainer,
          placeholder: S.placeholder,
          singleValue: S.singleValue,
          menu: S.menu,
          minWidth: minWidth || S.minWidth,
          borderTopRightRadius: borderTopRightRadius || S.borderTopRightRadius,
          borderBottomRightRadius:
            borderBottomRightRadius || S.borderBottomRightRadius,
        }}
        maxMenuHeight={maxMenuHeight}
        components={{
          DropdownIndicator,
          NoOptionsMessage,
          MultiValue,
          ...componentProps,
        }}
        options={data}
        placeholder={placeholder}
        isDisabled={isDisabled}
        labelType={labelType}
        className="react-select"
        classNamePrefix="select"
        defaultValue={defaultValue}
        {...rest}
      />
    </S.Select>
  );

export const AsyncSelect = props => <SelectAsync {...props} />;
