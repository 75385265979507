import React from "react";
import PropTypes from "prop-types";
import { Card, CardBody, CardTitle } from "reactstrap";
import { BlockUiBlankData } from "components/Structure";

import * as S from "./styles";

import classNames from "classnames";
import { Settings } from "react-feather";

export const CardList = props => {
  const {
    title,
    className: cardClass,
    type = "primary",
    icon,
    isLoading,
    isBlankData,
    descriptionError = "Sem Dados",
    childrenTitle,
    descriptionValue,
    descriptions,
    value,
    children,
    modalBtnColor = "primary",
    openModal = () => {},
  } = props;

  return (
    <BlockUiBlankData
      title={title}
      description={descriptionError}
      show={isLoading || isBlankData}
      isLoading={isLoading}
    >
      <Card
        type={type}
        className={classNames("pt-2 px-1", cardClass)}
        {...props}
      >
        <CardTitle className="d-flex flex-row justify-content-between align-items-center w-100 border-bottom mb-0">
          <div>
            <p
              className="font-bold fs-5 pb-0 mb-0"
              style={{
                lineHeight: "1px",
              }}
            >
              {descriptionValue}
            </p>
            <S.TotalTitle className="font-bold pt-0 mb-0 fs-2">
              <strong>{value}</strong>
            </S.TotalTitle>
          </div>
          <div className="d-flex align-items-start">
            <div className="d-flex flex-column text-end me-1">
              {!childrenTitle ? (
                <>
                  <h5 className="fs-4 mb-0">
                    {title}&nbsp;
                    <span>{icon || ""}</span>
                  </h5>
                  <small className="text-muted">{descriptions}</small>
                </>
              ) : (
                childrenTitle
              )}
            </div>
            <S.CustomBtn size="sm" color={modalBtnColor} onClick={openModal}>
              <Settings size={16} />
            </S.CustomBtn>
          </div>
        </CardTitle>
        <CardBody className="px-1 py-2">{children}</CardBody>
      </Card>
    </BlockUiBlankData>
  );
};

CardList.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  icon: PropTypes.element,
  descriptionValue: PropTypes.string,
  value: PropTypes.any,
  descriptions: PropTypes?.any,
};
