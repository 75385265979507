import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

export const handleHtmlForm = async (config, onConfirmCallBack) => {
  const title = config?.title;
  const html = config?.html;
  const icon = config?.icon;
  const textBtn = config?.textBtn;
  const textCancelBtn = config?.textCancelBtn;

  const textBtnDeny = config?.textBtnDeny;
  const showDenyButton = config?.showDenyButton;

  const cssConfigure = config?.css
    ? config?.css
    : {
        width: 600,
      };

  const { onDenyCallBack, onCancelCallBack, showCancelCallback } = config;

  const result = await MySwal.fire({
    title,
    html,
    icon,
    showCancelButton: true,
    showDenyButton,
    confirmButtonText: textBtn || "Sim, Continuar!",
    denyButtonText: textBtnDeny || "",
    cancelButtonText: textCancelBtn || "Cancelar",
    customClass: {
      confirmButton: "btn btn-primary",
      denyButton: "btn btn-warning ms-1",
      cancelButton: `btn btn-danger ${showDenyButton ? "ms-1" : "ms-1"}`,
    },
    ...cssConfigure,
    buttonsStyling: false,
    preConfirm: async e => {
      console.log({ e });
    },
  });

  if (result.value) {
    if (typeof onConfirmCallBack === "function" && textBtn) {
      onConfirmCallBack();
    }
    return {
      success: true,
    };
  } else if (result.isDenied && showDenyButton) {
    if (typeof onDenyCallBack === "function" && textBtn) {
      onDenyCallBack();
    }
    MySwal.close();
    return {
      success: false,
    };
  } else if (result.dismiss === MySwal.DismissReason.cancel) {
    if (showCancelCallback && textBtn) {
      onCancelCallBack();
    }
    return {
      success: false,
    };
  }
};
