import { format, toDate, formatDistanceToNowStrict } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";

export const Format = (date, mask = "dd/MM/yyyy - hh:mm:ss") =>
  format(new Date(date), mask, { locale: ptBR });

export const dateFormatUS = date =>
  format(new Date(date), "yyyy-MM-dd", { locale: ptBR });

export const dateFormat = Format;

export const lastDayOfMonth = dirtyDate => {
  const date = toDate(dirtyDate);
  const month = date.getMonth();
  date.setFullYear(date.getFullYear(), month + 1, 0);
  date.setHours(0, 0, 0, 0);
  return date;
};

// FIXME: Remover nao e o lugar dele (verificar aonde esta utilizando e migrar para o correto)
export const formatCNPJCPF = param => {
  if (!param) return "-";

  const CPF_LENGTH = 11;
  if (!param) return 0;
  const cnpjCpf = param && param.replace(/\D/g, "");

  if (cnpjCpf.length === CPF_LENGTH) {
    return cnpjCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4");
  }

  return cnpjCpf.replace(
    /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
    "$1.$2.$3/$4-$5",
  );
};

export const dateFormatInvoice = (data = null, location = "br") => {
  const date = data === null ? new Date() : new Date(data);

  let day = date.getDate();
  let month = date.getMonth() + 1;
  const year = date.getFullYear();

  if (month < 10) month = `0${month}`;
  if (day < 10) day = `0${day}`;

  const today =
    location === "br" ? `${day}/${month}/${year}` : `${year}-${month}-${day}`;
  return today;
};

export const hourFormat = (data = null) => {
  let timing = data.split("T")[1].split("-")[0];
  timing = timing.replace(".000Z", "");

  return `${timing}`;
};

export const formatDate = dateString => {
  if (!dateString) {
    return "-";
  }
  const options = { year: "numeric", month: "2-digit", day: "2-digit" };
  return new Date(dateString).toLocaleDateString("pt-BR", options);
};

const formatDistanceLocale = {
  lessThanXSeconds: "{{count}}s",
  xSeconds: "{{count}}s",
  halfAMinute: "30s",
  lessThanXMinutes: "{{count}}m",
  xMinutes: "{{count}}m",
  aboutXHours: "{{count}}h",
  xHours: "{{count}}h",
  xDays: "{{count}}d",
  aboutXWeeks: "{{count}}w",
  xWeeks: "{{count}}w",
  aboutXMonths: "{{count}}m",
  xMonths: "{{count}}m",
  aboutXYears: "{{count}}a",
  xYears: "{{count}}a",
  overXYears: "{{count}}a",
  almostXYears: "{{count}}a",
};

const formatDistanceok = (token, count, options) => {
  options = options || {};

  const result = formatDistanceLocale[token].replace("{{count}}", count);

  if (options.addSuffix) {
    if (options.comparison > 0) {
      return `in ${result}`;
    } else {
      return `Atualizado há ${result}`;
    }
  }

  return result;
};

export const formatDistance = date =>
  formatDistanceToNowStrict(date, {
    addSuffix: true,
    locale: {
      ...ptBR,
      formatDistance: formatDistanceok,
    },
  });

export const concatTime = data => {
  const [hora, minuto] = data?.split(":")?.map(Number) || ["--", "--"];

  const formattedHour = hora?.toString()?.padStart(2, "0");
  const formattedMinute = minuto?.toString()?.padStart(2, "0");

  return `${formattedHour}:${formattedMinute}`;
};

export const checkFormatedDateArrayUS = array => {
  const regex = /^\d{4}-\d{2}-\d{2}$/; // Regex para o formato "YYYY-MM-DD"
  return array.every(item => regex.test(item)); // Verifica se todos os itens seguem o formato
};
