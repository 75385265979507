import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

export const operationCanceled = async () => {
  await MySwal.fire({
    title: "Cancelada",
    text: "Operação finalizada.",
    icon: "error",
    customClass: {
      confirmButton: "btn btn-success",
    },
  });
};

export * from "./htmlForm";

export const handleIsLoading = async (isLoading, config) => {
  const title = config?.title || "Aguarde um Momento, Por Favor";
  const text = config?.text || "Solicitação em andamento";
  const icon = config?.icon || "info";

  const loading = await MySwal.fire({
    title,
    html: text,
    icon,
    allowEscapeKey: false,
    allowOutsideClick: false,
    didOpen: () => {
      MySwal.showLoading();
    },
  });

  if (!isLoading) {
    return loading.close();
  }

  return {
    loading,
  };
};

export const handleAjaxForm = async (config, onSend) => {
  const title = config?.title || "";
  const inputType = config?.inputType || "text";
  const confirmButtonText = config?.confirmButtonText || "Salvar";
  const cancelButtonText = config?.cancelButtonText || "Cancelar";

  const fieldName = config?.fieldName;

  const inputOptions = config?.inputOptions;
  const required = config?.required;
  const field = config?.field;

  const dataFixeds = config?.data;

  const showDenyButton = config?.showDenyButton;
  const textBtnDeny = config?.textBtnDeny || "Pular";

  const item = await MySwal.fire({
    title,
    input: inputType,
    inputOptions,
    customClass: {
      input: "mx-3",
      confirmButton: "btn btn-primary",
      denyButton: "btn btn-warning ms-1",
      cancelButton: "btn btn-danger ms-1",
    },
    buttonsStyling: false,
    inputAttributes: {
      autocapitalize: "off",
    },
    showDenyButton,
    showCancelButton: true,
    denyButtonText: textBtnDeny || "",
    confirmButtonText,
    cancelButtonText,
    showLoaderOnConfirm: true,
    preDeny: () => {
      return {
        success: false,
        msg: "Pulou etapa",
      };
    },
    preConfirm: async value => {
      if (!required && !value?.length) {
        return MySwal.showValidationMessage(
          `Não foi e possível enviar o ${field} em branco... Pule a etapa se preferir.`,
        );
      }
      if (required && !value?.length) {
        return MySwal.showValidationMessage(
          `O campo ${field} não pode ficar em branco!`,
        );
      }

      const res = await onSend({ [fieldName]: value, ...dataFixeds });
      return res;
    },
  }).then(result => {
    const values = result?.value;
    if (values.success) {
      return {
        success: true,
        msg: values?.msg,
      };
    }
    operationCanceled();
    return {
      success: false,
      msg: values?.msg,
    };
  });

  return { ...item };
};

export const handleConfirmAlert = async (config, onConfirmCallBack) => {
  const title = config?.title;
  const text = config?.text;
  const icon = config?.icon;
  const textBtn = config?.textBtn;
  const textCancelBtn = config?.textCancelBtn;
  const body = config?.body ?? {};

  const textBtnDeny = config?.textBtnDeny;
  const showDenyButton = config?.showDenyButton;

  const { onDenyCallBack, onCancelCallBack, showCancelCallback } = config;

  const result = await MySwal.fire({
    title,
    text,
    icon,
    showCancelButton: true,
    showDenyButton,
    confirmButtonText: textBtn || "Sim, Continuar!",
    denyButtonText: textBtnDeny || "",
    cancelButtonText: textCancelBtn || "Cancelar",
    customClass: {
      confirmButton: "btn btn-primary",
      denyButton: "btn btn-warning ms-1",
      cancelButton: `btn btn-danger ${showDenyButton ? "ms-1" : "ms-1"}`,
    },
    buttonsStyling: false,
  });

  if (result.value) {
    if (onConfirmCallBack) {
      onConfirmCallBack(body);
    }
    return {
      success: true,
    };
  } else if (result.isDenied && showDenyButton) {
    if (typeof onDenyCallBack === "function") {
      onDenyCallBack();
    }
    MySwal.close();
    return {
      success: false,
    };
  } else if (result.dismiss === MySwal.DismissReason.cancel) {
    if (showCancelCallback) {
      onCancelCallBack();
    }
    return {
      success: false,
    };
  }
};

export const handleConfirmAlertHtml = async (config, onConfirmCallBack) => {
  const title = config?.title;
  const html = config?.html;
  const icon = config?.icon;
  const textBtn = config?.textBtn;
  const showCancelButton = config?.showCancelButton;
  const textCancelBtn = config?.textCancelBtn;

  const textBtnDeny = config?.textBtnDeny;
  const showDenyButton = config?.showDenyButton;

  const { onDenyCallBack, onCancelCallBack, showCancelCallback } = config;

  const result = await MySwal.fire({
    title,
    html,
    icon,
    showCancelButton: showCancelButton || true,
    showDenyButton,
    confirmButtonText: textBtn || "Sim, Continuar!",
    denyButtonText: textBtnDeny || "",
    cancelButtonText: textCancelBtn || "Cancelar",
    customClass: {
      confirmButton: "btn btn-primary",
      denyButton: "btn btn-warning ms-1",
      cancelButton: `btn btn-danger ${showDenyButton ? "ms-1" : "ms-1"}`,
    },
    buttonsStyling: false,
  });

  if (result.value) {
    if (typeof onConfirmCallBack === "function" && textBtn) {
      onConfirmCallBack();
    }
    return {
      success: true,
    };
  } else if (result.isDenied && showDenyButton) {
    if (typeof onDenyCallBack === "function" && textBtn) {
      onDenyCallBack();
    }
    MySwal.close();
    return {
      success: false,
    };
  } else if (result.dismiss === MySwal.DismissReason.cancel) {
    if (showCancelCallback && textBtn) {
      onCancelCallBack();
    }
    return {
      success: false,
    };
  }
};
