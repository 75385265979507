import React, { useMemo } from "react";

import { useApp } from "context/AppContext";

import { CardHeader, Row, Col } from "reactstrap";

import { dateFormatInvoice, formatCurrency, limitCaracter } from "utils";
import * as S from "./mobileTable.styles";

const Title = ({ title }) => {
  if (title?.format === "monay") {
    return <S.TitleMonay>{formatCurrency(title?.value)}</S.TitleMonay>;
  }
  return <h3>{limitCaracter(30, title?.value)}</h3>;
};

export const Headers = ({ columns, dataItem }) => {
  const { handleCopy } = useApp();

  const title = useMemo(() => {
    const date = (columns || [])
      .filter(item => item?.isDate)
      .map(item => ({
        value: dataItem[item?.sortField],
        label: item?.name,
      }));
    const process = (columns || [])
      .filter(item => item?.isProcess)
      .map(item => dataItem[item?.sortField]);
    const isInfo = (columns || [])
      .filter(item => item?.isInfo)
      .map(item => {
        if (item?.isInfoTitle) {
          return {
            value: dataItem[item?.sortField],
            label: item?.name,
          };
        }
        return {
          value: dataItem[item?.sortField],
        };
      });
    const title = (columns || [])
      .filter(item => item?.isTitle)
      .map(item => {
        if (item?.format) {
          return {
            value: dataItem[item?.sortField],
            format: item?.format,
          };
        }
        return {
          value: dataItem[item?.sortField],
        };
      });
    const subTitle = (columns || [])
      .filter(item => item?.isSubTitle)
      .map(item => dataItem[item?.sortField]);
    const minTitle = (columns || [])
      .filter(item => item?.isMinTitle)
      .map(item => {
        if (item?.isMinTitleName) {
          return {
            value: dataItem[item?.sortField],
            label: item?.name,
          };
        }
        return dataItem[item?.sortField];
      });
    return {
      title: title[0],
      subTitle: subTitle[0],
      minTitle,
      process: process[0],
      info: isInfo,
      date: date[0],
    };
  }, [columns, dataItem]);

  return (
    <>
      {title?.date && (
        <S.DateHourTitle className="d-flex justify-content-end mb-0">
          {`${title?.date?.label}: ${dateFormatInvoice(title?.date?.value)}`}
        </S.DateHourTitle>
      )}

      <S.HeaderDiv className="mt-1 ">
        {title?.process && (
          <S.StatusDiv>
            <S.TopTitles
              onClick={() => {
                handleCopy(title?.process);
              }}
              copy={title?.copyStatusProcess}
            >
              {limitCaracter(20, title?.process)}
            </S.TopTitles>
          </S.StatusDiv>
        )}
        {title?.info?.length === 1 ? (
          <S.CodIdDiv>
            <S.TopTitles>{`${
              title?.info[0]?.label ?? `${title?.info[0]?.label}:`
            } ${title?.info[0]?.value}`}</S.TopTitles>
          </S.CodIdDiv>
        ) : (
          title?.info?.length > 1 && (
            <S.CodIdDiv>
              <S.TopTitles>
                {title?.info?.map((item, key) => (
                  <>
                    &nbsp;{key > 0 ? `/` : null}&nbsp;
                    {item?.label}&nbsp;
                    {item?.value}
                  </>
                ))}
              </S.TopTitles>
            </S.CodIdDiv>
          )
        )}
      </S.HeaderDiv>
      <CardHeader className="pb-0 pt-0">
        <Row>
          {title?.minTitle?.length === 1 ? (
            <Col md="12" className="mt-1">
              <p>{title?.minTitle[0] ? title?.minTitle[0] : null}</p>
            </Col>
          ) : (
            title?.minTitle?.length > 1 && (
              <Col md="12" className="mt-1">
                {title?.minTitle?.map(item => (
                  <p className="m-0">
                    {item?.label}:{item?.value}
                  </p>
                ))}
              </Col>
            )
          )}

          <Col md="12">
            <Title title={title?.title} />
          </Col>
          {title?.subTitle && (
            <Col md="12">
              <p>
                {title?.subTitle ? limitCaracter(25, title?.subTitle) : null}
              </p>
            </Col>
          )}
        </Row>
      </CardHeader>
    </>
  );
};
