export * from "./AcessGroup";
export * from "./treatMachineId";

export * from "./mxf";

export const formatFileSize = bytes => {
  if (!bytes) return "0 KB";

  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  let i = 0;
  let size = parseInt(bytes);

  while (size >= 1024 && i < sizes.length - 1) {
    size /= 1024;
    i++;
  }

  return `${size.toFixed(0)} ${sizes[i]}`;
};
