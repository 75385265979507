import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";

import { handleParamsStartEnd } from "redux/taxAnalises";

import { DateSimples } from "components/TaxAnalysis/List/Header/Filter/DateSimple";

import { Row, Col, Button } from "reactstrap";
import { useGlobalDateDashboard } from "constants/Types/date";

export const FilterAdvanced = () => {
  const { dataGlobalInicio, dataGlobalFim } = useGlobalDateDashboard();

  const {
    issue_date_end: end,
    issue_date_start: start,
    changed,
  } = useSelector(state => state.taxAnalises);
  const { control, handleSubmit, setValue } = useForm({});

  const itChanged = start && end && changed;

  const dispatch = useDispatch();

  const onSave = fields => {
    if (fields?.start && fields?.end) {
      dispatch(
        handleParamsStartEnd({
          issue_date_end: fields?.end
            ? format(fields?.end, "yyyy-MM-dd")
            : format(fields?.start, "yyyy-MM-dd"),
          issue_date_start: format(fields?.start, "yyyy-MM-dd"),
        }),
      );
    }
  };

  //essa função valida se já foi alterado anteriormente a data dentro do componente, porque se sim é para utilizar a data do componente como padrao, sem mudar de por exemplo do queima de caixo para o risco assumido e estiver a data da tela home do dashboard
  const showDados = () =>
    dispatch(
      handleParamsStartEnd({
        issue_date_end: itChanged ? end : dataGlobalFim,
        issue_date_start: itChanged ? start : dataGlobalInicio,
      }),
    );

  //TODO: deixei o useEffect sem retorno implicito, porque se nao causa erro de destroy is not a function
  useEffect(() => {
    showDados();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form onSubmit={handleSubmit(onSave)}>
      <h3>Filtro avançado</h3>
      <Row className="mt-1">
        <Col md="6">
          <DateSimples
            name="start"
            setValue={setValue}
            control={control}
            value={start}
          />
        </Col>
        <Col md="6">
          <DateSimples
            name="end"
            setValue={setValue}
            control={control}
            value={end}
            title="Data Final"
          />
        </Col>
      </Row>
      <Col md="12" className="text-center">
        <Button className="mt-1" color="success" type="onSumit">
          Filtrar
        </Button>
      </Col>
    </form>
  );
};
