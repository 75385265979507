export const MOVEMENT_TYPES = [
  {
    label: "ENTRADA",
    value: "ENTRADA",
  },

  {
    label: "TRANSFERÊNCIA",
    value: "TRANSFERENCIA",
  },

  {
    label: "DEVOLUÇÃO",
    value: "DEVOLUCAO",
  },
  {
    label: "SAIDA",
    value: "SAIDA",
  },
  {
    label: "TODOS",
    value: "TODOS",
  },
  {
    label: "USO CONSUMO",
    value: "USO_CONSUMO",
  },
  {
    label: "DOAÇÃO",
    value: "DOACAO",
  },
  {
    label: "PERDA",
    value: "PERDA",
  },
  {
    label: "ATIVO IMOBILIZADO",
    value: "ATIVO_IMOBILIZADO",
  },
];

export const ENTRIE_OPTIONS = [
  {
    label: "SAIDA",
    value: "SAIDA",
  },
];

export const SALE_OPTIONS = [
  {
    label: "ENTRADA",
    value: "ENTRADA",
  },
];

export const CartTotaisCfopsaLabels = {
  DEVOLUC_C: "Devolução compra",
  ENTRADA: "Entrada",
  ENTRADA_C: "Entrada compra",
  SAIDA: "Saída",
  SAIDA_C: "Saída compra",
  TRANSF: "Transferência",
};

export const CUT_COLUMNS_TEXTS_TYPES = {
  "CFOP Escrituração": "CFOP Esc.",
  "Tipo de Operação": "Tipo Op.",
  "Valor da Operação": "Valor Op.",
  "Base de Cálculo ICMS": "Base Cálc. ICMS",
  "Crédito ICMS no XML": "Créd. ICMS XML",
  "Aliquota ICMS(%)": "Aliq. ICMS",
};
