import { createSlice } from "@reduxjs/toolkit";

const state = {
  params: {
    page: 1,
    limit: 10,
  },
  filters: {},
  paramsLogs: {
    page: 1,
    limit: 10,
  },
  modal: {
    show: false,
    row: {},
  },
  paramsLogsError: {
    page: 1,
    limit: 10,
  },
  paramsLogsErrorProducts: {
    page: 1,
    limit: 10,
  },
  paramsSimilarity: {
    page: 1,
    limit: 10,
  },
  onEdit: false,
  onDisable: true,
  tableIndexView: null,
  tableIndexTmp: null,
  editCmfKey: false,
  onEditSchedule: false,
};

const painelCmfSlice = createSlice({
  name: "painelCmf",
  initialState: state,
  reducers: {
    handleParams: (state, { payload }) => {
      const field = payload?.field;
      state.params[field] = payload?.value;
      return state;
    },
    handleParamsLogs: (state, { payload }) => {
      const field = payload?.field;
      state.paramsLogs[field] = payload?.value;
      return state;
    },
    handleParamsLogsErrors: (state, { payload }) => {
      const field = payload?.field;
      state.paramsLogsError[field] = payload?.value;
      return state;
    },
    handleParamsLogsErrorsLogs: (state, { payload }) => {
      const field = payload?.field;
      state.paramsLogsErrorProducts[field] = payload?.value;
      return state;
    },
    handleClearFilter: state => {
      state.filters = {};
    },
    actionsFilterChange: (state, { payload }) => {
      const params = payload?.params;
      state.filters = {};
      Object.keys(params)
        .filter(field => params[field] !== undefined || params[field] !== "")
        .map(field => {
          if (params[field] === "" || params[field] === undefined) {
            delete state.filters[field];
          } else {
            state.filters[field] = params[field];
          }
        });
      return state;
    },
    handleSetOpenModal: (state, { payload }) => {
      state.modal.show = payload.show;
      state.modal.row = payload.row;
    },
    handleEdit: (state, { payload }) => {
      state.onEdit = payload;
    },
    handleDisableObs: (state, { payload }) => {
      state.onDisable = payload;
    },
    handleIndexTableView: (state, { payload }) => {
      state.tableIndexView = payload;
    },
    handleIndexTableTmp: (state, { payload }) => {
      state.tableIndexTmp = payload;
    },
    handleParamsSimilarity: (state, { payload }) => {
      const field = payload?.field;
      state.paramsSimilarity[field] = payload?.value;
      return state;
    },
    handleEditCmfKey: (state, { payload }) => {
      state.editCmfKey = payload;
      return state;
    },
    handleEditSchedule: (state, { payload }) => {
      state.onEditSchedule = payload;
      return state;
    },
  },
});

export const actions = painelCmfSlice.actions;

export default painelCmfSlice.reducer;
