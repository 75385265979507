import { useRef, useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from "context/AuthContext";
import useSWR from "swr";

import { useToast } from "./useToast";

const CMF_BASE_URL = "https://cmf.agendador.mixfiscal.com.br/";

export const useCmfAPI = () => {
  const onRejected = error => {
    // console.log(error.response);
    return Promise.reject(error);
  };

  const apiRef = useRef(
    axios.create({
      baseURL: CMF_BASE_URL,
      withCredentials: false,
    }),
  );

  apiRef.current.interceptors.request.use(config => {
    config.headers = {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "Authorization",
      "Access-Control-Allow-Methods": "GET, POST, OPTIONS, PUT, PATCH, DELETE",
      "Content-Type": "application/json;charset=utf-8",
      // Connection: "keep-alive",
      "X-Powered-By": "X-Powered-By",
    };
    return config;
  });

  apiRef.current.interceptors.response.use(response => response, onRejected);

  return apiRef.current;
};

export const useAPICmfToast = () => {
  const [isLoading, setIsLoading] = useState(false);
  const api = useCmfAPI();
  const { addToast } = useToast();

  const onSend = async ({
    messageSuccess,
    messageFail,
    endpoint,
    type = "POST",
    data: dataFields = {},
  }) => {
    try {
      setIsLoading(true);
      const { data } = await api({
        method: type,
        url: endpoint,
        data: dataFields,
      });

      if (data.response ?? data.success) {
        addToast({
          type: "success",
          title: "SUCESSO",
          description: messageSuccess,
        });
      }
      setIsLoading(false);
      return data;
    } catch (err) {
      addToast({
        type: "error",
        title: "FALHA",
        description: messageFail,
      });
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    onSend,
  };
};

export const useFetchCmf = (url, axiosConfigs, config) => {
  const api = useCmfAPI();
  const { tokenCompanies, revalidateAll, setRevalidateAll } = useAuth();

  const { data, error, mutate, revalidate, isValidating } = useSWR(
    url,
    async urlReturn => {
      const response = await api.get(urlReturn, axiosConfigs);
      return response.data;
    },
    config,
  );

  // Revalida todas as requisicoes para carregar os dados corretamente!
  useEffect(() => {
    if (revalidateAll?.length) {
      revalidate();
      setRevalidateAll();
    }
  }, [tokenCompanies, revalidate, revalidateAll, setRevalidateAll]);

  return {
    data,
    error,
    isError: !!error,
    isLoading: (!error && !data && String(data) !== "") || isValidating,
    isValidating,
    mutate,
    revalidate,
  };
};
