import React from "react";

import { Label, Spinner } from "reactstrap";
import { convertToRaw, ContentState, EditorState } from "draft-js";

import { Editor } from "react-draft-wysiwyg";
import { Controller } from "react-hook-form";

import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

import { LabelInput } from "../TextField";

import "@styles/react/libs/editor/editor.scss";

import * as S from "../TextField/styles";

export const EditorText = ({
  name,
  control,
  label,
  helperText,
  error,
  width,
  isLoading,
  required = false,
  ...props
}) =>
  control && (
    <Controller
      rules={{ required }}
      name={name}
      control={control}
      render={({ field }) => {
        const blocksFromHtml = htmlToDraft(field?.value);

        const contentState = ContentState.createFromBlockArray(
          blocksFromHtml.contentBlocks,
          blocksFromHtml.entityMap,
        );

        const newEditorState = EditorState.createWithContent(contentState);

        return (
          <S.InputFieldContainer>
            {required ? (
              <LabelInput name={name} label={label} />
            ) : (
              <Label hidden={!label} error={!field?.editorState && error}>
                {label}
              </Label>
            )}
            <Editor
              name={name}
              defaultEditorState={newEditorState}
              onEditorStateChange={value => {
                field.onChange({
                  target: {
                    name,
                    value: draftToHtml(convertToRaw(value.getCurrentContent())),
                  },
                });
              }}
              {...props}
            />
            {isLoading && (
              <S.Loading>
                <Spinner size="sm" />
              </S.Loading>
            )}
            <S.InputFieldHelperText error={!field?.editorState && error}>
              {helperText}
            </S.InputFieldHelperText>
          </S.InputFieldContainer>
        );
      }}
    />
  );
