import React from "react";
import { Controller } from "react-hook-form";

import { Input, Label } from "reactstrap";

export const Switch = ({
  name,
  control,
  label,
  helperText,
  error,
  color = "primary",
  className = "",
  ...props
}) => {
  const colorItem = `form-check-${color} form-switch ${className}`;
  if (control) {
    return (
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <div className={colorItem}>
            <Input
              type="switch"
              name={name}
              id={`switch-${name}`}
              onChange={onChange}
              value={value}
              checked={value}
            />
            <Label for={`switch-${name}`} className="form-check-label mx-1">
              {label}
            </Label>
          </div>
        )}
      />
    );
  }

  const { value, onChange } = props;

  return (
    <Input
      value={value}
      name={name}
      onChange={v =>
        onChange ? onChange({ target: { name, value: v } }) : null
      }
      label={label}
    />
  );
};
