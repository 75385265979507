import React, { useState } from "react";

import { Badge, Popover, PopoverHeader, PopoverBody } from "reactstrap";

import { formatCurrency, limitCaracter } from "utils";

import * as S from "./Products.styles";

import FireIcon from "assets/icone-fire.png";
import RiskIcon from "assets/icone-risk.png";
import { ButtonsBloqueio } from "components/Products/ProductModal/ProductCard/Cards/CardHeader/BloqueioProduto/Button/btnBloqueio";

const percente = value =>
  Number(value / 100).toLocaleString(undefined, {
    style: "percent",
    minimumFractionDigits: 2,
  });

const PopoverItems = ({ title, titleCard, id, limit = 35 }) => {
  const [isShowPopover, setIsShowPopover] = useState("");
  const PopoverId = `Popover-${id}`;

  const OpenPopover = () =>
    setIsShowPopover(isShowPopover === PopoverId ? "" : PopoverId);

  return title?.length > 20 ? (
    <>
      <span
        onMouseEnter={() => OpenPopover()}
        onMouseLeave={() => setIsShowPopover("")}
        id={PopoverId}
      >
        {limitCaracter(limit, title)}
      </span>
      <Popover
        isOpen={Boolean(isShowPopover)}
        placement="top"
        toogle={OpenPopover}
        target={PopoverId}
      >
        <PopoverHeader>{titleCard}</PopoverHeader>
        <PopoverBody>{title}</PopoverBody>
      </Popover>
    </>
  ) : (
    title
  );
};

export const columns = [
  {
    name: "",
    width: "60px",
    isType: "queima",
    cell: row => (
      <>
        <S.IconeCustom src={FireIcon} />
        {/* TODO: comentando atualemnte para realizar dev na master, quando
        estiver 100% a feature descomentar. // se tiver botao de bloquear coloco
        100px se n fica 60px */}
        {/* <div className="mb-2 ms-1">
          <ButtonsBloqueio
            data={{
              pcn_nome: row?.xprod,
              pcn_ean: row?.ean ?? row?.cean,
              pcn_cod_interno: row?.cprod,
              cnpj: row?.cnpj_empresa,
            }}
            keyItem={row?.key}
          />
        </div> */}
      </>
    ),
  },
  {
    name: "",
    // TODO: comentando atualemnte para realizar dev na master, quando estiver 100% a feature descomentar.
    // se tiver botao de bloquear coloco 100px se n fica 60px
    width: "60px",
    isType: "risco",
    cell: row => (
      <>
        <S.IconeCustom src={RiskIcon} />
        {/* <div className="ms-1 mb-2">
          <ButtonsBloqueio
            data={{
              pcn_nome: row?.xprod,
              pcn_ean: row?.ean ?? row?.cean,
              pcn_cod_interno: row?.cprod,
              cnpj: row?.cnpj_empresa,
            }}
            keyItem={row?.key}
          />
        </div> */}
      </>
    ),
  },
  {
    name: "EAN",
    omit: true,
    sortField: "cean",
    isOmitMobile: true,
    isShowTag: true,
    type: "secondary",
  },
  {
    name: "Cod.",
    omit: true,
    sortField: "cprod",
    isOmitMobile: true,
    isShowTag: true,
    type: "secondary",
  },
  {
    name: "NCM",
    omit: true,
    sortField: "ncm",
    isOmitMobile: true,
    isShowTag: true,
    type: "secondary",
  },
  {
    name: "CEST",
    omit: true,
    sortField: "cest",
    isOmitMobile: true,
    isShowTag: true,
    type: "secondary",
  },
  {
    name: "CFOP",
    omit: true,
    sortField: "cfop",
    isOmitMobile: true,
    isShowTag: true,
    type: "secondary",
  },
  {
    name: "Produto",
    sortable: true,
    typesDefaults: [],
    sortField: "xprod",
    isTitle: true,
    minWidth: "320px",
    position: 1,
    cell: row => {
      return (
        <div className="d-flex justify-content-left align-items-center">
          <div className="d-flex flex-column">
            <h5 className="user-name pt-1">
              {!row.xprod && <Badge>SEM DESCRIÇÃO DO PRODUTO</Badge>}
              {PopoverItems({
                titleCard: "Descrição",
                title: row.xprod,
                limit: 25,
                id: row?.key,
              })}
            </h5>
            <div
              className="user-name pb-1"
              style={{
                gridGap: 2,
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              <Badge color="light-secondary">Cod. Interno: {row.cprod}</Badge>
              {row?.cean && (
                <Badge color="light-secondary">EAN: {row?.cean}</Badge>
              )}
              &nbsp;
              {row?.ncm && (
                <Badge color="light-secondary">NCM: {row?.ncm}</Badge>
              )}
              &nbsp;
              {row?.cest && (
                <Badge color="light-secondary">CEST: {row?.cest}</Badge>
              )}
              &nbsp;
              {row?.cfop && (
                <Badge color="light-secondary">CFOP: {row?.cfop}</Badge>
              )}
            </div>
          </div>
        </div>
      );
    },
  },

  {
    name: "QUEIMA DE CAIXA ICMS",
    sortable: true,
    typesDefaults: [],
    sortField: "queima_caixa_icms",
    minWidth: "160px",
    isType: "queima",
    position: 1,
    cell: row => {
      return formatCurrency(row?.queima_caixa_icms);
    },
  },
  {
    name: "QUEIMA DE CAIXA PIS/COFINS",
    sortable: true,
    typesDefaults: [],
    sortField: "queima_caixa_pis_cofins",
    minWidth: "160px",
    isType: "queima",
    position: 1,
    cell: row => {
      return formatCurrency(row?.queima_caixa_pis_cofins);
    },
  },
  {
    name: "Risco Assumido ICMS",
    sortable: true,
    typesDefaults: [],
    sortField: "risco_assumido_icms",
    minWidth: "160px",
    isType: "risco",
    position: 1,
    cell: row => {
      return formatCurrency(row?.risco_assumido_icms);
    },
  },
  {
    name: "Risco Assumido PIS/COFINS",
    sortable: true,
    typesDefaults: [],
    sortField: "risco_assumido_pis_cofins",
    minWidth: "160px",
    isType: "risco",
    position: 1,
    cell: row => {
      return formatCurrency(row?.risco_assumido_pis_cofins);
    },
  },

  {
    name: "Valor do Produto",
    sortable: true,
    typesDefaults: [],
    sortField: "vprod",
    minWidth: "150px",
    position: 1,
    typeFormatValue: "monay",
    cell: row => {
      return formatCurrency(row?.vprod);
    },
  },

  {
    name: "Porcentagem de Queima de Caixa ICMS por período",
    sortable: true,
    typesDefaults: [],
    isType: "queima",
    sortField: "porcentagem_queima_caixa_icms_no_periodo",
    minWidth: "160px",
    typeFormatValue: "percent",
    position: 1,
    cell: row => {
      return `${percente(row?.porcentagem_queima_caixa_icms_no_periodo)}`;
    },
  },
  {
    name: "Porcentagem de Queima de Caixa PIS/COFINS por período",
    sortable: true,
    typesDefaults: [],
    isType: "queima",
    sortField: "porcentagem_queima_caixa_pis_cofins_no_periodo",
    minWidth: "160px",
    typeFormatValue: "percent",
    position: 1,
    cell: row => {
      return `${percente(row?.porcentagem_queima_caixa_pis_cofins_no_periodo)}`;
    },
  },
  {
    name: "Porcentagem de Risco de Assumido ICMS por período",
    sortable: true,
    typesDefaults: [],
    isType: "risco",
    typeFormatValue: "percent",
    sortField: "porcentagem_risco_assumido_icms_no_periodo",
    minWidth: "150px",
    position: 1,
    cell: row => {
      return `${percente(row?.porcentagem_risco_assumido_icms_no_periodo)}`;
    },
  },
  {
    name: "Porcentagem de Risco de Assumido PIS/COFINS por período",
    sortable: true,
    typesDefaults: [],
    isType: "risco",
    typeFormatValue: "percent",
    sortField: "porcentagem_risco_assumido_pis_cofins_no_periodo",
    minWidth: "150px",
    position: 1,
    cell: row => {
      return `${percente(
        row?.porcentagem_risco_assumido_pis_cofins_no_periodo,
      )}`;
    },
  },
];
