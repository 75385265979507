/* eslint-disable no-multi-assign */
import { createSlice } from "@reduxjs/toolkit";

import {
  addOrEditSubsRecursively,
  handleChangeUpdateNameRecursively,
  handleDeleteStageRecursively,
} from "./Xmls/reducer";

export const initialtags = {
  modal: {
    isShow: false,
    id: "",
  },
  tags: [],
};

const initialState = () => {
  return initialtags;
};

export const tagsSlice = createSlice({
  name: "tagsXmls",
  initialState,
  reducers: {
    handleModalOpen: (state, { payload }) => {
      state.modal.isShow = payload?.show;
      state.modal.id = payload?.id;
    },
    handleListNewTags: (state, { payload }) => {
      const copy = [...state.tags];
      const newItems = payload;
      state.tags = [...copy, newItems];
    },
    handleSetTagsDetail: (state, { payload }) => {
      state.tags = payload;
    },
    handleChangeUpdateName: (state, { payload }) => {
      const { targetId, field, value } = payload;
      const list = [...state.tags];
      handleChangeUpdateNameRecursively(list, targetId, field, value);
      state.tags = list;
    },
    handleListNewModuleList: (state, { payload }) => {
      state.tags = payload;
    },
    handleChangeUpdateSub: (state, { payload }) => {
      const list = [...state.tags];
      if (payload?.field === "sub") {
        const keyDady = payload?.keyDady;
        const key = payload?.key;

        if (list[keyDady] && list[keyDady].sub && list[keyDady].sub[key]) {
          list[keyDady].sub[key][payload.field] = payload?.value;
        } else {
          console.error("Invalid keyDady, sub, or key");
        }
      }
      state.tags = list;
    },
    handleChangeUpdateSub: (state, { payload }) => {
      const list = [...state.tags];
      const { key, field, value } = payload;

      if (field === "sub") {
        if (!list[key].sub && !payload?.key) {
          // Se não existir 'sub', cria um array vazio
          list[key].sub = [];
        }

        if (payload?.keyDady) {
          list[payload?.keyDady].sub[key] = value;
        } else {
          list[key].sub.push(value);
        }
        // Adiciona a nova etapa 'sub' ao array existente
      } else {
        // Atualiza outros campos normalmente
        list[key][field] = value;
      }

      state.tags = list;
    },
    handleChangeUpdate: (state, { payload }) => {
      const selected = (state.tags || []).filter(item =>
        (item?.id === payload?.field) !== "sub"
          ? payload?.key
          : payload?.keyDady,
      );

      const copy = (state.tags || []).filter(item => item?.id !== payload?.key);

      const itemSel = (selected || []).map(item => {
        return {
          ...item,
          [payload?.field]: payload?.value,
        };
      });

      const newList = [...itemSel, ...copy];
      state.tags = newList;
    },
    handleRecursiveAddSubs: (state, { payload }) => {
      addOrEditSubsRecursively(state.tags, payload?.id);
    },
    handleDeleteStage: (state, { payload }) => {
      const { targetId } = payload;

      if (targetId !== undefined) {
        const list = [...state.tags];
        handleDeleteStageRecursively(list, targetId);
        state.tags = list;
      }
    },
    handleDeleteModule: (state, { payload }) => {
      const copy = [...state.tags];
      const remove = copy.filter((item, key) => key !== payload?.key);
      state.tags = [...remove];
    },
  },
});

export const {
  handleModalOpen,
  handleChangeUpdateName,
  handleChangeUpdateSub,
  handleListNewTags,
  handleChangeUpdate,
  handleDeleteStage,
  handleRecursiveAddSubs,
  handleSetTagsDetail,
} = tagsSlice.actions;

export default tagsSlice.reducer;
