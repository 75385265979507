import React from "react";

import { useSkin } from "@hooks/useSkin";

import successData from "theme/vuex/src/assets/images/pages/success-data.jpg";
import underMaintenance from "theme/vuex/src/assets/images/pages/under-maintenance.svg";
import underMaintenanceDark from "theme/vuex/src/assets/images/pages/under-maintenance-dark.svg";

import * as S from "./alertList.styles";

import "@styles/base/pages/page-misc.scss";

export const AlertList = ({ title, description, isSuccess }) => {
  const { skin } = useSkin();

  const getTypeImage = () => {
    if (skin === "dark" && !isSuccess) {
      return underMaintenanceDark;
    }
    if (!isSuccess) {
      return underMaintenance;
    }
    return successData;
  };

  return (
    <S.CardErrorPage>
      <div className="w-100 text-center">
        <h2 className="mb-1">{title}</h2>
        <p className="mb-2">{description}</p>

        <img className="img-fluid" src={getTypeImage()} alt={title} />
      </div>
    </S.CardErrorPage>
  );
};
