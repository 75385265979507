import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { DisappearedLoading } from "react-loadingg";

import * as S from "./load.styles";
import { Col, Row, Spinner } from "reactstrap";

const colorDefault = "#A2C659";

export const LoadPulse = ({ isShow = true, size = "default" }) =>
  isShow && (
    <S.Container size={size}>
      <DisappearedLoading size={size} color={colorDefault} />
    </S.Container>
  );

export const LoadCircle = ({
  color = colorDefault,
  width = "30px",
  height = "30px",
}) => <CircularProgress style={{ color, width, height }} />;

export const LoadPlaceholder = ({
  children: Children,
  show,
  showNoData = false,
  textNoData = "Sem dados para o período selecionado",
  styleBackDrop,
  fontWeight = "normal",
}) => (
  <S.ContainerPlaceholder>
    {show && (
      <S.Backdrop>
        <LoadCircle />
      </S.Backdrop>
    )}
    {showNoData && (
      <S.Backdrop style={styleBackDrop}>
        <S.PlaceholderContainer>
          <S.TitleBlank fontWeight={fontWeight}>
            {textNoData || "Sem dados para o período selecionado"}
          </S.TitleBlank>
        </S.PlaceholderContainer>
      </S.Backdrop>
    )}
    {Children}
  </S.ContainerPlaceholder>
);

export const LoadPrefix = ({ prefix, past = "" }) => (
  <div style={{ display: "flex", alignItems: "center", width: "50px" }}>
    {prefix ?? `R$ `}
    <LoadPulse size="small" />
    {past}
  </div>
);

export const NoDataPlaceholder = ({ children: Children, show }) => (
  <S.ContainerPlaceholder>
    {show && (
      <S.Backdrop>
        <S.PlaceholderContainer>
          <S.TitleBlank>Sem dados para o período selecionado</S.TitleBlank>
        </S.PlaceholderContainer>
      </S.Backdrop>
    )}
    {Children}
  </S.ContainerPlaceholder>
);

export const LoadingData = () => (
  <Row>
    <Col className="text-center p-5">
      <div className="my-auto">
        <Spinner color="primary" />
        <h4>Carregando dados....</h4>
      </div>
    </Col>
  </Row>
);
