import React, { useState } from "react";
import { Input, Label, Spinner, Tooltip, Badge } from "reactstrap";
import { Controller } from "react-hook-form";
import CurrencyInput from "react-currency-input-field";
import { useSkin } from "@hooks/useSkin";

import * as S from "./styles";
import { Info } from "react-feather";

export const helperTextError = ({ value, error, helperText }) => (
  <S.InputFieldHelperText error={!value && error}>
    {helperText}
  </S.InputFieldHelperText>
);

export const LabelInput = ({ name, label }) => {
  const [isShowPopover, setIsShowPopover] = useState("");
  const PopoverId = `Popover_${name}`;

  const OpenPopover = () => {
    isShowPopover === PopoverId
      ? setIsShowPopover("")
      : setIsShowPopover(PopoverId);
  };

  return (
    <>
      <Label
        for={name}
        style={{
          cursor: "pointer",
        }}
        onMouseEnter={() => OpenPopover()}
        onMouseLeave={() => setIsShowPopover("")}
        className="form-label mx-1"
        id={PopoverId}
      >
        {label} <Info size={10} />
      </Label>
      {PopoverId && (
        <Tooltip
          isOpen={Boolean(isShowPopover)}
          placement="right"
          target={PopoverId}
        >
          O campo {label} e obrigátorio
        </Tooltip>
      )}
    </>
  );
};

export const TextField = ({
  name,
  control,
  label,
  helperText,
  error,
  width,
  isDisabledPopover = false,
  isLoading,
  required = false,
  defaultValue,
  ...props
}) =>
  control ? (
    <Controller
      rules={{ required }}
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <S.InputFieldContainer>
          {label && (
            <Label for={props?.name} hidden={!label} error={!value && error}>
              {label}
              {required && <span className="text-danger">*</span>}
            </Label>
          )}
          <Input
            value={value ?? ""}
            onChange={onChange}
            error={!value && error}
            defaultValue={defaultValue}
            {...props}
            {...(props.type === "checkbox" ? { checked: value } : {})}
          />
          {isLoading && (
            <S.Loading>
              <Spinner size="sm" />
            </S.Loading>
          )}
          <S.InputFieldHelperText error={!value && error}>
            {helperText}
          </S.InputFieldHelperText>
        </S.InputFieldContainer>
      )}
    />
  ) : (
    <S.InputFieldContainer width={width}>
      {label && (
        <Label for={props?.name} hidden={!label}>
          {label}
          {required && <span className="text-danger">*</span>}
        </Label>
      )}
      <Input {...props} />
      {error && (
        <S.InputFieldHelperText error={!props?.value && error}>
          {helperText}
        </S.InputFieldHelperText>
      )}
    </S.InputFieldContainer>
  );

export const TextMultiField = ({
  name,
  control,
  label,
  helperText,
  error,
  width,
  isDisabledPopover = false,
  isLoading,
  required = false,
  defaultValue,
  ...props
}) => {
  const [currentValue, setCurrentValue] = useState("");

  return control ? (
    <Controller
      rules={{ required }}
      name={name}
      control={control}
      render={({ field: { onChange, value = [] } }) => (
        <S.InputFieldContainer>
          {required && !isDisabledPopover ? (
            <LabelInput name={name} label={label} />
          ) : (
            <Label className="mx-1" error={!value && error}>
              {label}
            </Label>
          )}

          <Input
            className="mb-1"
            value={currentValue}
            onChange={e => setCurrentValue(e.target.value)}
            onKeyDown={e => {
              if (e.key === " " && currentValue.trim()) {
                e.preventDefault();
                const newValue = [...value, currentValue.trim()];
                onChange(newValue);
                setCurrentValue("");
              }
            }}
            error={!value && error}
            {...props}
          />
          <div className="d-flex flex-wrap gap-1 mb-1">
            {value?.map((item, index) => (
              <Badge
                key={`${item}-${index}`}
                color="primary"
                className="d-flex align-items-center"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  const newValues = value.filter((_, i) => i !== index);
                  onChange(newValues);
                }}
              >
                {item} ×
              </Badge>
            ))}
          </div>

          {isLoading && (
            <S.Loading>
              <Spinner size="sm" />
            </S.Loading>
          )}
          <S.InputFieldHelperText error={!value && error}>
            {helperText}
          </S.InputFieldHelperText>
        </S.InputFieldContainer>
      )}
    />
  ) : (
    <S.InputFieldContainer width={width}>
      <Label hidden={!label}>{label} </Label>
      <Input {...props} />
      {error && (
        <S.InputFieldHelperText error={!props?.value && error}>
          {helperText}
        </S.InputFieldHelperText>
      )}
    </S.InputFieldContainer>
  );
};

export const TextHiddenField = ({ name, control, ...props }) => (
  <Controller
    name={name}
    control={control}
    render={({ field: { onChange, value } }) => (
      <input type="hidden" value={value} onChange={onChange} {...props} />
    )}
  />
);

export const MaskField = ({
  name,
  control,
  label,
  required,
  onChange: onChangeProps,
  width,
  mask,
  maskChar,
  value: valueDefault,
  helperText,
  error,
  isLoading,
  ...props
}) => {
  const { skin } = useSkin();

  return control ? (
    <Controller
      rules={{ required }}
      name={name}
      control={control}
      as={S.InputMaskCustom}
      render={({ field: { onChange, value } }) => {
        return (
          <S.InputFieldContainer width={width} positionRelative={true}>
            <Label>
              {label} {required && <span className="text-danger">*</span>}
            </Label>
            <S.InputMaskCustom
              mask={mask}
              maskChar={maskChar}
              value={value}
              onChange={e => {
                const { value } = e.target;
                onChange(value);
                if (onChangeProps) {
                  onChangeProps(value);
                }
              }}
              skin={skin}
            >
              {inputProps => (
                <S.InputField
                  {...props}
                  {...inputProps}
                  type="text"
                  disableUnderline
                />
              )}
            </S.InputMaskCustom>
            {isLoading && (
              <S.Loading>
                <Spinner size="sm" />
              </S.Loading>
            )}
            <S.InputFieldHelperText error={!value && error}>
              {helperText}
            </S.InputFieldHelperText>
          </S.InputFieldContainer>
        );
      }}
    />
  ) : (
    <S.InputFieldContainer width={width}>
      <S.InputFieldLabel>
        {label}
        {required && <span className="text-danger">*</span>}
      </S.InputFieldLabel>
      <S.InputMaskCustom
        skin={skin}
        mask={mask}
        value={valueDefault}
        onChange={onChangeProps}
      >
        {inputProps => (
          <S.InputField
            {...props}
            {...inputProps}
            type="text"
            disableUnderline
          />
        )}
      </S.InputMaskCustom>
      <S.InputFieldHelperText error={!valueDefault && error}>
        {helperText}
      </S.InputFieldHelperText>
    </S.InputFieldContainer>
  );
};

export const CurrencyField = ({
  name,
  label,
  prefix = "R$",
  control,
  error,
  required,
  helperText,
  onChange: onChangeProps,
  ...props
}) =>
  control ? (
    <Controller
      rules={{ required }}
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <S.CurrencyField>
          <Label>
            {label}
            {required && <span className="text-danger">*</span>}
          </Label>
          <CurrencyInput
            value={value}
            placeholder={`${prefix} 0,00`}
            prefix={prefix}
            decimalsLimit={2}
            onValueChange={value => {
              onChange(value);
            }}
            onChange={e => {
              const { value } = e.target;
              if (onChangeProps) {
                onChangeProps(value);
              }
            }}
            {...props}
          />
          <S.InputFieldHelperText error={!value && error}>
            {helperText}
          </S.InputFieldHelperText>
        </S.CurrencyField>
      )}
    />
  ) : (
    <S.CurrencyField>
      <Label>
        {label}
        {required && <span className="text-danger">*</span>}
      </Label>
      <CurrencyInput
        placeholder={`${prefix} 0,00`}
        prefix={prefix}
        decimalsLimit={2}
        onValueChange={value => onChangeProps && onChangeProps(value)}
        {...props}
      />
    </S.CurrencyField>
  );
