import { createSlice } from "@reduxjs/toolkit";

import { format } from "date-fns";

const initialRelatorioCfop = {
  tipoMovimento: "TODOS",
  page: 50,
  isShowFilter: false,
};

const date = new Date();
const firstDay = new Date(date.getFullYear(), date.getMonth(), 1, 1);
const currentDay = new Date(
  date.getFullYear(),
  date.getMonth(),
  date.getDate(),
  1,
);

const initialState = () => {
  return {
    ...initialRelatorioCfop,
    issue_date_start: format(firstDay, "yyyy-MM-dd"),
    issue_date_end: format(currentDay, "yyyy-MM-dd"),
    page: 1,
    limit: 10,
    showModal: {
      type: "",
      show: false,
      // data: [],
      total: "",
    },
    editMovement: {
      edit: "",
      data: "",
      changes: {},
    },
    editType: {
      edit: "",
      data: "",
      changes: {},
    },
  };
};

export const relatorioCfopSlice = createSlice({
  name: "relatorioCfopCsts",
  initialState,
  reducers: {
    handleChangeDate: (state, { payload }) => {
      if (payload?.issue_date_start) {
        state.issue_date_start = payload.issue_date_start;
      }
      if (payload?.issue_date_end) {
        state.issue_date_end = payload.issue_date_end;
      }
    },
    handleChangeMovement: (state, { payload }) => {
      state.tipoMovimento = payload.tipoMovimento;
    },
    handlePageAndLimit: (state, { payload }) => {
      state.page = payload;
    },
    handleToogleFilters: (state, { payload }) => {
      state.isShowFilter = payload.isShowFilter;
    },
    handleParamsFields: (state, { payload }) => {
      const field = payload?.field;
      state[field] = payload?.value;
      return state;
    },
    handleShowModal: (state, { payload }) => {
      state.showModal = payload;
    },
    handleEditMovementType: (state, { payload }) => {
      state.editMovement = {
        ...state.editMovement,
        edit: payload.edit,
        data: payload.data,
        changes: {
          [payload.edit]: payload.data,
        },
      };
    },
    handleEditType: (state, { payload }) => {
      state.editType = {
        ...state.editType,
        edit: payload.edit,
        data: payload.data,
        changes: {
          [payload.edit]: payload.data,
        },
      };
    },
  },
});

export const {
  handleChangeDate,
  handleChangeMovement,
  handlePageAndLimit,
  handleParamsFields,
  handleShowModal,
  handleToogleFilters,
  handleEditMovementType,
  handleEditType,
} = relatorioCfopSlice.actions;

export default relatorioCfopSlice.reducer;
