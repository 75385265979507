export const INVOICE_COMPARTILHAMENTO = {
  WHATSAPP: "WhatsApp",
  COPY_LINK: "Copiar Link",
};

export const INVOICE_DOWNLOADS = {
  XML: "Baixar XML",
  DANFE: "Gerar Danfe",
};

export const INVOICES_MANIFEST_CODES = {
  CIENCIA: "210210",
  CONFIRMACAO: "210200",
  DOCUMENTO_DESCONHECIDO: "210220",
  COMPRA_NAO_REALIZADA: "210240",
};

export const INVOICES_MANIFEST_CODES_LABELS = {
  [INVOICES_MANIFEST_CODES.CIENCIA]: {
    label: "Ciência",
  },
  [INVOICES_MANIFEST_CODES.CONFIRMACAO]: {
    label: "Confirmação",
  },
  [INVOICES_MANIFEST_CODES.DOCUMENTO_DESCONHECIDO]: {
    label: "Documento desconhecido",
  },
  [INVOICES_MANIFEST_CODES.COMPRA_NAO_REALIZADA]: {
    label: "Compra não realizada",
  },
};

export const INVOICES_PRODUCTS_STATUS = {
  DIVERGENTE: "divergencia",
  NAO_PROCESSADO: "nao-processado",
  NAO_DIVERGENTE: "nao-divergente",
};

export const INVOICE_POPOVER_TITLE_TYPES = {
  QUEIMA_CAIXA: "Queima de caixa",
  RISCO_ASSUMIDO: "Risco assumido",
};

export const INVOICE_TYPES_TRANSPORTE = {
  0: "Contratação do Frete por conta do Remetente (CIF)",
  1: "Contratação do Frete por conta do Destinatário (FOB)",
  2: "Contratação do Frete por conta de Terceiros",
  3: "Transporte Próprio por conta do Remetente",
  4: "Transporte Próprio por conta do Destinatário",
  9: "Sem Ocorrência de Transporte.",
};

export const TYPES_SHOW_FUNCTION = {
  MANDATORY: true,
  "NOT-MANDATORY": true,
  "NOT-REQUEST": true,
  "BLIND-ORDER-NOT-REQUIRED": false,
  "BLIND-ORDER-MANDATORY": false,
};

export const DOWNLOAD_NOTES_DATE_TYPES = [
  {
    label: "Mês",
    value: "month",
  },
  {
    label: "Ano",
    value: "year",
  },
  {
    label: "TODO PERÍODO",
    value: "all",
  },
];
